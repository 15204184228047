import CustomRedoMenu from "./redoMenu";
import CustomUndoMenu from "./undoMenu";

export const RedoUndoMenuConf = [
  {
    key: "customUndo",
    factory() {
      return new CustomUndoMenu();
    },
  },
  {
    key: "customRedo",
    factory() {
      return new CustomRedoMenu();
    },
  },
];
