import store from "@/store";
import { DomEditor, SlateEditor, SlatePath } from "@wangeditor/editor";

export function tokens2Tree(tokens) {
  const rootNode = {
    id: 1,
    depth: 0,
    children: [],
  };
  const treeMap = {};
  let nextId = 2;

  // 递归获取父节点存在 text 的节点
  const getParentNodeWithText = (node) => {
    const parentNode = treeMap[node.parentId];

    if (!parentNode) {
      return null;
    }

    return parentNode.text ? parentNode : getParentNodeWithText(parentNode);
  };

  // 递归清除节点没有 text 的树
  const filterEmptyNode = (node) => {
    if (node.children.length < 1) {
      return;
    }

    node.children = node.children.filter((x) => x.text);

    for (const item of node.children) {
      filterEmptyNode(item);
    }
  };

  // 遍历数组中的每个对象
  tokens.forEach((token) => {
    const depth = token.depth || 1;

    // 找到适当的父节点
    let parentNode = rootNode;
    for (let i = 1; i < depth; i++) {
      const lastChild = parentNode.children[parentNode.children.length - 1];
      if (!lastChild || lastChild.depth < i) {
        const newChild = {
          id: nextId++,
          depth: i,
          children: [],
          parentId: parentNode.id,
        };
        treeMap[newChild.id] = newChild;
        parentNode.children.push(newChild);
        parentNode = newChild;
      } else {
        treeMap[lastChild.id] = lastChild;
        parentNode = lastChild;
      }
    }

    // 解决层级顺序不是有序递增的情况下会生成空节点
    // 如果父级节点没有 text，递归查找到对应有 text 的父级节点
    if (parentNode.text === undefined && parentNode.depth > 0) {
      const parentNodeWithText = getParentNodeWithText(parentNode);

      if (parentNodeWithText) {
        parentNode = parentNodeWithText;
      }
    }

    // 将对象添加到父节点的子节点中
    const newNode = {
      id: nextId++,
      ...token,
      children: [],
      parentId: parentNode.id,
    };

    treeMap[newNode.id] = newNode;
    parentNode.children.push(newNode);
  });

  // 递归删除空节点
  filterEmptyNode(rootNode);

  return rootNode.children;
}

export function tree2Tokens(tree) {
  const arr = [];

  function traverse(node) {
    arr.push(node);
    if (node.children) {
      node.children.forEach((child) => {
        traverse(child);
      });
    }
  }

  tree.forEach((node) => {
    traverse(node);
  });

  return arr;
}

/**
 * 转义 markdown 单行文本数据
 * @param {String} originText markdown 单行字符串
 * @returns {Object} { innerHTML, children } children 结构跟 wangeditor 的节点 children 结构保持一致
 */
function parseTextStyle(originText) {
  if (originText.length < 1) {
    return {
      innerHTML: "",
      children: [{ text: "" }],
    };
  }

  const ITALIC_FLAG = "_italic_"; // 斜体
  const BOLD_FLAG = "_bold_"; // 加粗
  const ITALIC_BOLD_FLAG = "_italic-bold_"; // 斜体加粗

  const starPattern = /(\*{1,3}(.*?)\*{1,3})/g;
  const splitPattern = RegExp(
    `${ITALIC_FLAG}|${BOLD_FLAG}|${ITALIC_BOLD_FLAG}`,
    "g"
  );

  // 识别文本中的斜体、加粗、并生成对应 wangeditor 的节点样式属性
  const nodes = originText
    .replace(starPattern, (_, $1) => {
      const isBoldAndItalic = $1[2] === "*";
      const isBold = $1[1] === "*";
      const isItalic = $1[0] === "*";

      if (isBoldAndItalic) {
        return `${ITALIC_BOLD_FLAG}${$1}${ITALIC_BOLD_FLAG}`;
      } else if (isBold) {
        return `${BOLD_FLAG}${$1}${BOLD_FLAG}`;
      } else if (isItalic) {
        return `${ITALIC_FLAG}${$1}${ITALIC_FLAG}`;
      }

      return $1;
    })
    .split(splitPattern)
    .map((text) => {
      const node = {
        text: text.replace(starPattern, "$2"),
        italic: false,
        bold: false,
      };

      if (text[2] === "*") {
        node.bold = true;
        node.italic = true;
      } else if (text[1] === "*") {
        node.bold = true;
      } else if (text[0] === "*") {
        node.italic = true;
      }

      return node;
    })
    .filter((x) => x.text);

  const html = nodes
    .map((node) => {
      if (node.bold && node.italic) {
        return `<strong><em>${node.text}</em></strong>`;
      } else if (node.bold) {
        return `<strong>${node.text}</strong>`;
      } else if (node.italic) {
        return `<em>${node.text}</em>`;
      } else {
        return node.text;
      }
    })
    .join("");

  return {
    innerHTML: html,
    children: nodes,
  };
}

/**
 * 解析获取列表前缀样式
 * @param {Object} textBlock
 * @returns
 */
function parseListStyle(textBlock) {
  const style = {
    margin: `5px 0 5px ${textBlock.level * 20}px`,
  };
  switch (textBlock.level) {
    case 0:
      style.previousSymbol = "•"; // 第一层级
      break;
    case 1:
      style.previousSymbol = "◦"; // 第一层级
      break;
    case 2:
      style.previousSymbol = "▪"; // 第三层级
      break;
    default:
      style.previousSymbol = "▪"; // 其他层级
  }
  return style;
}

// from: wangeditor\packages\list-module\src\module\render-elem.tsx
function getOrderedItemNumber(editor, elem) {
  const { type, level = 0, ordered = false } = elem;
  if (!ordered) {
    return -1; // 不是有序列表
  }

  let num = 1; // 默认值 1
  let curElem = elem;
  let curPath = DomEditor.findPath(editor, curElem);

  // 第一个元素，直接返回 1
  if (curPath[0] === 0) return 1;

  while (curPath[0] > 0) {
    const prevPath = SlatePath.previous(curPath);
    const prevEntry = SlateEditor.node(editor, prevPath);
    if (prevEntry == null) break;
    const prevElem = prevEntry[0]; // 上一个节点
    const {
      level: prevLevel = 0,
      type: prevType,
      ordered: prevOrdered,
    } = prevElem;

    // type 不一致，退出循环，不再累加 num
    if (prevType !== type) break;
    // prevLevel 更小，退出循环，不再累加 num
    if (prevLevel < level) break;

    if (prevLevel === level) {
      // level 一样，如果 ordered 不一样，则退出循环，不再累加 num
      if (prevOrdered !== ordered) break;
      // level 一样，order 一样，则累加 num
      else num++;
    }

    // prevLevel 更大，不累加 num ，继续向前
    curElem = prevElem;
    curPath = prevPath;
  }

  return num;
}

/**
 * 解析纯文本为自定义 block 结构
 * @param {*} nodes 纯文本列表
 * @returns
 */
export function parseTextsToBlocks(nodes) {
  const blocks = [];
  const reverseBlocks = []; // 解析优化，空间换时间

  for (const node of nodes) {
    const text = node?.trimStart();
    if (text?.length < 1) {
      continue;
    }

    // 解析解析标题： h1 - h5
    const [headerFlag] = text.match(RegExp("(^#+)\\s+")) || [];
    if (headerFlag?.length && headerFlag.length < 6) {
      const headerItem = {
        textType: "title",
        type: `header${headerFlag.length}`,
        htmlFlag: `h${headerFlag.length}`,
        ...parseTextStyle(text.replace(headerFlag, "")),
      };

      blocks.push(headerItem);
      reverseBlocks.unshift(headerItem);
      continue;
    }

    // 解析列表 ol、li
    const [listFlag, , ordered] =
      text.match(RegExp("^(-\\s+|((\\d+).)+)")) || [];
    if (listFlag) {
      const listItem = {
        textType: "list",
        type: "list-item",
        ordered: !!ordered,
        previousSymbol: ordered,
        level: 0,
        htmlFlag: !!ordered ? "ol" : "li",
        ...parseTextStyle(text.replace(listFlag, "")),
      };

      // 更新相邻的 level，规则如下：
      // 1. 前一个元素是有序的，当前元素无序，则当前元素层级按照前一个元素层级 + 1（前一个元素无序，后一个元素有序同理）
      // 2. 前一个元素和当前元素同类（有序或无序），则当前元素层级跟前一个元素保持一致
      // 3. 前一个元素为非列表元素，直接初始化
      const previousSibling = reverseBlocks[0];
      const isListItem = previousSibling?.type === listItem.type;
      const isOrdered = previousSibling?.ordered === listItem.ordered;
      if (isListItem && isOrdered) {
        if (typeof previousSibling.level === "undefined") {
          listItem.level = 0;
        } else {
          listItem.level = previousSibling.level;
        }
      } else if (isListItem && !isOrdered) {
        const sampleNode = reverseBlocks.find((item) => {
          return item.type === "list-item" && item.ordered === listItem.ordered;
        });

        if (sampleNode) {
          if (previousSibling?.ordered && previousSibling.level >= 0) {
            listItem.level = previousSibling.level + 1;
          } else {
            listItem.level = sampleNode.level;
          }
        } else {
          listItem.level = previousSibling.level + 1;
        }
      } else if (!isListItem) {
        listItem.level = 0;
      }

      // 追加列表前缀
      const style = parseListStyle(listItem);
      if (listItem.ordered) {
        listItem.innerHTML = `${listItem.previousSymbol} ${listItem.innerHTML}`;
      } else {
        listItem.innerHTML = `${style.previousSymbol} ${listItem.innerHTML}`;
      }

      listItem.style = style;
      blocks.push(listItem);
      reverseBlocks.unshift(listItem);
      continue;
    }

    const commonBlock = {
      textType: "context",
      type: "paragraph",
      htmlFlag: "p",
      ...parseTextStyle(text),
    };
    blocks.push(commonBlock);
    reverseBlocks.unshift(commonBlock);
  }

  reverseBlocks.length = 0;
  console.log("textsToBlocks", blocks);
  return blocks;
}

export function parseHtmlString(editor) {
  const tokens = [];
  const reverseTokens = [];
  const headerPattern = RegExp("header");
  const listPattern = RegExp("list");

  for (const node of editor?.children || []) {
    const isHeader = headerPattern.test(node.type);
    const isListItem = listPattern.test(node.type);

    if (!isHeader && !isListItem) {
      continue;
    }

    const token = {
      depth: 0,
      text: "",
      type: node.type,
      ordered: node.ordered,
      level: node.level,
    };
    token.text = node.children?.map((item) => item.text).join("") || "";

    if (token.text.length < 1) {
      continue;
    }

    if (isHeader) {
      token.depth = Number(node.type.match(/\d+/));
      token.textType = "title";
    } else if (isListItem) {
      // 查找 tokens 中最近的 header, 并重新设置列表前置符号
      const header = reverseTokens.find((item) => item.type.match("header"));
      const style = parseListStyle(token);

      if (token.ordered) {
        const previousSymbol = getOrderedItemNumber(editor, node);
        token.text = `${previousSymbol}. ${token.text}`;
      } else {
        token.text = `${style.previousSymbol} ${token.text}`;
      }

      token.textType = "list";

      if (token.level && header) {
        token.depth = header.depth + token.level + 1;
      } else if (header) {
        token.depth = header.depth + 1;
      } else {
        token.depth = token.level || 1;
      }
    }

    tokens.push(token);
    reverseTokens.unshift(token);
  }

  const minDepth =
    tokens.length > 0 ? Math.min(...tokens.map((token) => token.depth)) : 0;

  // 保证非文档标题的 token 的 depth 永远大于 0
  if (minDepth === 0) {
    for (const token of tokens) {
      token.depth += 2;
    }
  } else if (minDepth - 1) {
    for (const token of tokens) {
      token.depth -= minDepth - 1;
    }
  }

  const titleDepth =
    tokens.length > 0 ? Math.min(...tokens.map((token) => token.depth)) : 1;
  if (store.state.markdown.markdownTitle) {
    tokens.unshift({
      depth: titleDepth - 1,
      text: store.state.markdown.markdownTitle || "",
      type: `header${titleDepth}`,
      textType: "title",
      docTitle: true
    });
  }

  const zeroDepth = tokens.some((token) => token.depth === 0);

  // 插入标题后，标题的层级可能为 0 ，转换为 tree 的时候，层级要求必须大于 0
  // 这里做一次更新层级操作
  if (zeroDepth) {
    for (const token of tokens) {
      token.depth += 1;
    }
  }

  console.log("parseHtmlString tokens", tokens);

  return tokens2Tree(tokens);
}
