 <template>
	<div id="app">
		<!-- 登录组件 -->
		<Login />
		<!-- 小程序跳转二维码 -->
		<miniProgramCode />
		<!-- 次数耗尽弹框 -->
		<buyDialog />
		<!-- 积分弹框 -->
		<vipRenewal />
		<!-- 路由 -->
		<router-view />
	</div>
</template>

<script>
import Login from "@/components/Login.vue";
import miniProgramCode from "@/components/miniProgramCode/miniProgramCode.vue";
import buyDialog from "@/components/buyDialog";
import vipRenewal from "@/components/vipRenewal";
// vipRenewal
export default {
  name: "app",
  //import引入的组件需要注入到对象中才能使用
  components: {
    Login,
    miniProgramCode,
    buyDialog,
    vipRenewal,
  },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss'>
#app {
  .el-input.no-arrows .el-input__inner::-webkit-inner-spin-button,
  .el-input.no-arrows .el-input__inner::-webkit-outer-spin-button {
    display: none;
  }
}

@font-face {
  font-family: "YouSheBiaoTiHei";
  src: url("assets/font/YouSheBiaoTiHei.ttf") format("truetype");
  /* 其他字体属性 */
}
</style>