import { Loading, MessageBox } from "element-ui";

import store from "../store/index";
export function debounce(fn, delay = 200) {
  let timer;
  return function () {
    let arg = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arg);
    }, delay);
  };
}

export function chunk(array, count) {
  let result = [];
  //遍历输出成员
  array.forEach((item, index) => {
    let temp = Math.floor(index / count);
    //检验数组是否初始化
    if (!(result[temp] instanceof Array)) {
      result[temp] = new Array();
    }
    result[temp].push(item);
  });
  return result;
}

export function isEmptyObj(obj) {
  return obj && Object.keys(obj).length == 0;
}

export function createLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-bg",
  };
  const loading = Loading.service(option);
  store.commit("setExampleLoading", loading);
  return loading;
}

export function createAiLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-ai-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-ai-bg",
  };
  const loading = Loading.service(option);
  store.commit("setExampleLoading", loading);
  return loading;
}
export function createHjLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-hj-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-bg",
  };
  const loading = Loading.service(option);
  store.commit("setExampleLoading", loading);
  return loading;
}

export function createCaiLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-cai-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-bg",
  };
  const loading = Loading.service(option);
  store.commit("setExampleLoading", loading);
  return loading;
}


// JS 计算两个时间间隔多久（时分秒）
export function twoTimeInterval(startTime, endTime) {
  // 开始时间
  let d1 = startTime.replace(/\-/g, "/");
  let date1 = new Date(d1);

  // 结束时间
  let d2
  try {
    d2 = endTime.replace(/\-/g, "/");
  } catch {
    d2 = endTime;
  }

  let date2 = new Date(d2);

  // 时间相差秒数
  let dateDiff = date2.getTime() - date1.getTime();

  // 计算出相差天数
  let days = Math.floor(dateDiff / (24 * 3600 * 1000));

  // 计算出小时数
  let residue1 = dateDiff % (24 * 3600 * 1000); // 计算天数后剩余的毫秒数
  let hours = Math.floor(residue1 / (3600 * 1000));

  // 计算相差分钟数
  let residue2 = residue1 % (3600 * 1000); // 计算小时数后剩余的毫秒数
  let minutes = Math.floor(residue2 / (60 * 1000));

  // 计算相差秒数
  let residue3 = residue2 % (60 * 1000); // 计算分钟数后剩余的毫秒数
  let seconds = Math.round(residue3 / 1000);

  let returnVal =
    ((days == 0) ? "" : days + "天") +
    ((hours == 0) ? "" : hours + "时") +
    ((minutes == 0) ? "" : minutes + "分")

  return returnVal;
}

export function isHasBridge() {
  if (!window.bridge) {
    this.$confirm('网页端暂不支持使用此功能,请前往小程序或客户端体验', '提示', {
      confirmButtonText: '去下载',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      window.open('http://www.creword.com.cn/downloadCenter');
    })
    return false
  } else {
    return true
  }
}


// 获取地址栏参数
export function getQuerys(e) {
  if (!e) return ''
  var t = {},
    r = [],
    n = '',
    a = ''
  try {
    var i = []
    if (
      (e.indexOf('?') >= 0 &&
        (i = e.substring(e.indexOf('?') + 1, e.length).split('&')),
        i.length > 0)
    )
      for (var o in i)
        (n = (r = i[o].split('='))[0]), (a = r[1]), (t[n] = a)
  } catch (s) {
    t = {}
  }
  return t
}