// [
//   "bold",
//   "underline",
//   "italic",
//   "through",
//   "code",
//   "sub",
//   "sup",
//   "clearStyle",
//   "color",
//   "bgColor",
//   "fontSize",
//   "fontFamily",
//   "indent",
//   "delIndent",
//   "justifyLeft",
//   "justifyRight",
//   "justifyCenter",
//   "justifyJustify",
//   "lineHeight",
//   "insertImage",
//   "deleteImage",
//   "editImage",
//   "viewImageLink",
//   "imageWidth30",
//   "imageWidth50",
//   "imageWidth100",
//   "divider",
//   "emotion",
//   "insertLink",
//   "editLink",
//   "unLink",
//   "viewLink",
//   "codeBlock",
//   "blockquote",
//   "headerSelect",
//   "header1",
//   "header2",
//   "header3",
//   "header4",
//   "header5",
//   "todo",
//   "redo",
//   "undo",
//   "fullScreen",
//   "enter",
//   "bulletedList",
//   "numberedList",
//   "insertTable",
//   "deleteTable",
//   "insertTableRow",
//   "deleteTableRow",
//   "insertTableCol",
//   "deleteTableCol",
//   "tableHeader",
//   "tableFullWidth",
//   "insertVideo",
//   "uploadVideo",
//   "editVideoSize",
//   "uploadImage",
//   "codeSelectLang",
// ]

export const toolbarConfig = {
  toolbarKeys: [
    "logo",
    "customUndo",
    "customRedo",
    "customHeaderSelect",
    "customBold",
    "clear",
    "|",

    "fontSize",
    "fontFamily",
    "underline",
    "italic",
    // 'color',
    "|",

    "bulletedList",
    "numberedList",

    "justifyLeft",
    "justifyRight",
    "justifyCenter",
    "justifyJustify",
    "|",

    "uploadImage"
  ],
};