import { getHeaderType, isMenuDisabled, setHeaderType } from "../helper";

class HeaderButtonMenuBase {
  title = "";
  type = ""; // 'header1' 'header2' 等
  tag = "button";

  /**
   * 获取选中节点的 node.type
   * @param editor editor
   */
  getValue(editor) {
    return getHeaderType(editor);
  }

  isActive(editor) {
    return this.getValue(editor) === this.type;
  }

  isDisabled(editor) {
    return isMenuDisabled(editor);
  }

  exec(editor, value) {
    const { type } = this;
    let newType;
    if (value === type) {
      // 选中的 node.type 和当前 type 一样，则取消
      newType = "paragraph";
    } else {
      // 否则，则设置
      newType = type;
    }

    setHeaderType(editor, newType);
  }
}

export default HeaderButtonMenuBase;
