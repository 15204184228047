import { CLEAR_SVG } from '@/views/pcMarkdown/config/wange/constants'
import { MarkdownEditor } from '@/views/pcMarkdown/editor/utils/node';

class ClearMenu {
  constructor() {
    this.title = "清空";
    this.iconSvg = CLEAR_SVG;
    this.tag = "button";
  }

  isActive() {
    return false;
  }

  getValue() {
    return "";
  }

  isDisabled(editor) {
    return editor.isEmpty();
  }

  exec(editor) {
    if (this.isDisabled(editor)) {
      return;
    }

    editor.clear();
    MarkdownEditor.emit('setMarkdownTitle', '')
  }
}

export const ClearMenuConf = [
  {
    key: "clear",
    factory() {
      return new ClearMenu();
    },
  },
];
