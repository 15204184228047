import BoldMenu from './BoldMenu';

export const BoldMenuConf = [
  {
    key: "customBold",
    factory() {
      return new BoldMenu();
    },
  },
];
