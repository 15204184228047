export const OPTIONS = [
  {
    value: "header1",
    text: "标题1",
    style: {
      fontSize: "24px",
      fontWeight: "bold",
      background: "#f8f9fa",
      border: "none",
    },
    renderEl: {
      fontSize: "2em",
      fontWeight: "bold",
    },
  },
  {
    value: "header2",
    text: "标题2",
    style: {
      fontSize: "22px",
      fontWeight: "bold",
      background: "#f8f9fa",
      border: "none",
    },
    renderEl: {
      fontSize: "1.5em",
      fontWeight: "bold",
    },
  },
  {
    value: "header3",
    text: "标题3",
    style: {
      fontSize: "20px",
      fontWeight: "bold",
      background: "#f8f9fa",
      border: "none",
    },
    renderEl: {
      fontSize: "1.17em",
      fontWeight: "bold",
    },
  },
  {
    value: "header4",
    text: "标题4",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      background: "#f8f9fa",
      border: "none",
    },
    renderEl: {
      fontSize: "1em",
      fontWeight: "bold",
    },
  },
  {
    value: "header5",
    text: "标题5",
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      background: "#f8f9fa",
      border: "none",
    },
    renderEl: {
      fontSize: "0.83em",
      fontWeight: "550",
    },
  },
  {
    value: "paragraph",
    text: "正文",
    style: {
      fontSize: "14px",
      fontWeight: "400",
      background: "#f8f9fa",
      border: "none",
    },
    renderEl: {
      fontSize: "1em",
      fontWeight: "400",
    },
  },
];
