import store from "@/store";
import { LOGO_SVG } from "@/views/pcMarkdown/config/wange/constants";
import { MarkdownEditor } from "@/views/pcMarkdown/editor/utils/node";

class LogoMenu {
  constructor() {
    this.title = "模板库";
    this.iconSvg = LOGO_SVG;
    this.tag = "button";
    this.alwaysEnable = true
  }

  isActive() {
    return false;
  }

  getValue() {
    return "";
  }

  isDisabled() {
    return false;
  }
  
  exec(editor) {
    if (this.isDisabled(editor)) {
      return;
    }

    MarkdownEditor.emit('cursorChange', false)
    store.commit("markdown/SET_COMMAND_DIALOG_VISIBLE", true);
  }
}

export const LogoMenuConf = {
  key: "logo",
  factory() {
    return new LogoMenu();
  },
};
