import { TEXTAREA_EDIT_TYPE } from "@/views/pcMarkdown/config/wange/node";
import { REDO_SVG } from "@/views/pcMarkdown/config/wange/constants";

function redo(editor) {
  editor.redo();

  const { redos } = editor.history;
  const operations = redos[redos.length - 1];

  if (!operations) {
    return;
  }

  if (redos.length === 1 && editor?.isEmpty()) {
    redo(editor);
    return;
  }

  const op = operations[0];
  const nextOp = operations[1];

  // 跳过 e-textarea 阶段
  const hasCurrentTextarea =
    op?.node?.type === TEXTAREA_EDIT_TYPE ||
    op?.node?.children?.some((item) => {
      return item.type === TEXTAREA_EDIT_TYPE;
    });
  const hasNextTextarea =
    nextOp?.node?.type === TEXTAREA_EDIT_TYPE ||
    nextOp?.node?.children?.some((item) => {
      return item.type === TEXTAREA_EDIT_TYPE;
    });

  if (hasCurrentTextarea || hasNextTextarea) {
    redo(editor);
  }
}

export default class CustomRedoMenu {
  constructor() {
    this.title = "重做";
    this.iconSvg = REDO_SVG;
    this.tag = "button";
  }

  isActive() {
    return false;
  }

  getValue() {
    return "";
  }

  isDisabled(editor) {
    const { redos } = editor.history;
    const operations = redos[redos.length - 1];

    if (!operations) {
      return true 
    }

    // if (redos.length === 1 && editor?.isEmpty()) {
    //   return true
    // }

    return false;
  }

  exec(editor) {
    if (this.isDisabled(editor)) {
      return;
    }

    redo(editor);
  }
}
