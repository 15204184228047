import Vue from "vue";
import Vuex from "vuex";
import markdown from './modules/markdown'
import user from './modules/user'
import drawing from './modules/drawing'
import persistedState from "vuex-persistedstate";
import { myShenLunNum } from '@/api/essay'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {}, //用户信息
    isShowLogin: false,
    isShowEdit: false,
    shenLunCount: 0,
    // 登录提示唯一
    tipsSwitch: true,
    /* 编辑器Vuex */
    ocrContent: "", //编辑器选中的内容
    continueContent: "", //子组件续写的内容
    onlineBasePath: "https://fafs.antiplagiarize.com/",
    wordPath: "https://work.creword.com",
    fileUrl: 'https://fafs.antiplagiarize.com/',
    // 挖潜
    wqUrl: 'https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/wqzk/',
    speedUrl: 'https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/speed_web/',
    payment: 'https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/',
    resourcesUrl: "https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/xyhh/",
    lockClickText: {},
    buyTipsInfo: {},
    sidebarActive: 0,
    bugDialog: false,
    headerTitle: '',
    currentWord: null,
    // 溯源内容
    rootsContent: "",
    // Loading 实例
    exampleLoading: "",
    // 绘画生成关键字
    AiImageKey: "",
    drawWorkFlag: false,//是否是ai绘画弹出会员弹框
    currentDocumentId: '',
    buyDialogText: {
      title: '开通会员',
      content: '当前次数已经用完,开通VIP可获取更多次数',
    },

  },
  getters: {},
  mutations: {
    setCurrentDocumentId(state, data) {
      state.currentDocumentId = data
    },
    setBuyDialogText(state, payload) {
      state.buyDialogText = payload
    },
    setHeaderTitle(state, data) {
      state.headerTitle = data
    },
    setDrawWorkId(state, data) {
      state.drawWorkFlag = data;
    },
    setShenLunCount(state, data) {
      state.shenLunCount = data
    },
    setAiImageKey(state, data) {
      state.AiImageKey = data;
    },
    setExampleLoading(state, data) {
      state.exampleLoading = data;
    },
    setRootsContent(state, data) {
      state.rootsContent = data;
    },
    setIsShowLogin(state, data) {
      state.isShowLogin = data;
    },
    setBugDialog(state, payload) {
      state.bugDialog = payload
    },
    setIsShowEdit(state, data) {
      state.isShowEdit = data;
    },
    setOcrContent(state, data) {
      state.ocrContent = data;
    },
    setContinueContent() {
      state.continueContent = data;
    },
    ChangeSideBarId(state, payload) {
      state.sidebarActive = payload;
    },
    setLockClickText(state, data) {
      state.lockClickText = data;
    },
    setBuyTipsInfo(state, data) {
      state.buyTipsInfo = data;
    },
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    setCurrentWord(state, data) {
      console.log(data);
      state.currentWord = data;
    },

    setTipsSwitch(state, payload) {
      console.log(payload, 'payload');
      state.tipsSwitch = payload;
    },
  },
  actions: {
    getMyShenLunNum({ commit }) {
      myShenLunNum().then(res => {
        if (res.code === 200) {
          commit('setShenLunCount', res.data)
        }
      })
    },
  },
  modules: {
    markdown,
    user,
    drawing
  },
  // plugins: [persistedState()],
});
