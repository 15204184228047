import HeaderModule from './header'
import LogoModule from './logo'
import RedoUndoModule from './redoUndo'
import ClearModule from './clear'
import TextStyleModule from './textStyle'
import { Boot } from "@wangeditor/editor";

Boot.registerModule(HeaderModule)
Boot.registerModule(LogoModule)
Boot.registerModule(RedoUndoModule)
Boot.registerModule(ClearModule)
Boot.registerModule(TextStyleModule)