<template v-if="show">
	<div class='miniProgramCode'>
		<el-dialog
			title="扫码查看"
			:close-on-click-modal="false"
			@opened="onOpened"
			:visible.sync="showDialog"
			@closed="onClose"
			class="code"
		>
			<div class="content">
				<div class="notes">微信扫码可在移动端查看</div>

				<div
					class="QRCode"
					ref="qrcode_box"
				></div>

			</div>
		</el-dialog>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { isLogin } from "@/utils/auth";
import Cookies from "js-cookie";
import QRCode from "qrcodejs2";
export default {
  name: "miniProgramCode",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      show: false,
      loading: false,
      showDialog: false,
      codeImg: "",

      // 参数
      query: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async onOpened() {
      new QRCode(this.$refs.qrcode_box, {
        text: `https://mall.writemall.com/code?url=${this.url}`,
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: 3,
      });
    },

    // 关闭清除数据
    onClose() {
      this.show = false;
      this.url = "";
      this.$refs.qrcode_box.innerHTML = "";
    },

    /**
     * url /pages/index/index type:String
     *
     * params type:String || Object
     * id=123&obj=JSON.stringify(obj)
     */
    openMiNiProgramCode(url, params) {
      const pass = isLogin();

      if (!pass) {
        const token =
          localStorage.getItem("auth_token") || Cookies.get("auth_token");

        if (params) {
          this.url = url + `&${this.montage(params)}&token=${token}`;
        } else {
          this.url = url + `&token=${token}`;
        }
        console.log(this.url);
        this.show = true;
        this.showDialog = true;
      } else {
        this.$message("请先登录");
      }
    },

    montage(data) {
      if (typeof data !== "object") return params;
      let str = "";
      let list = Object.keys(data);
      list.forEach((el, i) => {
        if (i === list.length - 1) {
          if (typeof data !== "object") {
            str += `${el}=${data[el]}`;
          } else {
            str += `${el}=${data[el]}`;
          }
        } else {
          if (typeof data !== "object") {
            str += `${el}=${data[el]}&`;
          } else {
            str += `${el}=${data[el]}&`;
          }
        }
      });
      return str;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    window.openMiNiProgramCode = this.openMiNiProgramCode;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
.miniProgramCode {
  .code {
    ::v-deep .el-dialog {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;

          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          position: unset;
          margin-right: 20px;
        }
      }
      .el-dialog__body {
        padding: 45px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-family: PingFang SC;
            font-weight: 500;
            margin-bottom: 30px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>