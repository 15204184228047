const drawing = {
    namespaced: true,
    state: {
        allScrollTop: 0,
        collectScrollTop: 0
    },
    mutations: {
        setAllScrollTop(state, data) {
            state.allScrollTop = data;
        },
        setCollectScrollTop(state, data) {
            state.collectScrollTop = data;
        },
    },
};

export default drawing;
