<template>
  <div
    v-if="show"
    class="van-popup"
  >
    <div v-if="show" class="vip-renewal">
      <div class="close" @click="close">
        <img src="@/assets/images/ai/close_dialog.png" />
      </div>
      <div class="title">
        <img src="@/assets/images/ai/buy-dialog-icon.png" />
        <div>{{ info.title }}</div>
      </div>

      <div class="content" v-html="info.content"></div>

      <div @click="buyClick(1)" class="buy-vip-btn" v-if="!info.isVip">
        <img src="@/assets/images/ai/buy-dialog-vip.png" />
        <span>购买VIP</span>
        <span class="vip-desc">（{{ info.vipText }}）</span>
      </div>

      <div @click="buyClick(2)" class="buy-recharge-btn" v-if="info.isRecharge">
        <img src="@/assets/images/ai/buy-dialog-recharge-icon.png" />
        <span>充值积分</span>
      </div>

      <div
        @click="toPay"
        :class="['no-recharge-btn', !info.isPay && 'is-disabled']"
      >
        <span>{{ info.payText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VipRenewal",
  data() {
    return {
      requestLoading: false,
    };
  },
  watch: {
    show() {
      this.requestLoading = false;
    },
  },
  computed: {
    show() {
      return this.$store.state.markdown.vipDialogVisible;
    },
    info() {
      return this.$store.state.markdown.vipDialogContext;
    },
  },
  methods: {
    buyClick(type) {
      if (type == 1) {
        const fullPath = this.$router.resolve({
          name: "vipPage",
        }).href;
        window.open(fullPath, "_blank");
      } else if (type == 2) {
        window.open("/buyIntegration");
      }

      this.$store.commit("markdown/SET_VIP_DIALOG_VISIBLE", false);
    },
    close() {
      this.$store.commit("markdown/SET_VIP_DIALOG_VISIBLE", false);
    },
    async toPay() {
      if (this.requestLoading) {
        return;
      }
      this.requestLoading = true

      try {
        this.$store.commit("markdown/SET_VIP_DIALOG_VISIBLE", false);
        await this.info.toPayCallback();
      } catch (e) {
        console.error("操作失败", e);
      } finally {
        this.requestLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  position: fixed;
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}

.vip-renewal {
  height: max-content;
  width: 295px;
  box-shadow: 0 2px 10px 2px rgba(90, 93, 100, 0.15);
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
  .close {
    text-align: right;
    padding: 5px 10px 0 0;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .title {
    text-align: center;
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;

    img {
      width: 64px;
      height: 70px;
    }
  }

  .content {
    margin: 12px 16px 30px 16px;
    line-height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #777;
  }

  .buy-vip-btn {
    width: 248px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    border-radius: 17px;
    background: linear-gradient(180deg, #376ff7 0%, #618fff 100%);
    margin: 0 24px 12px 24px;
    font-size: 12px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }

    .vip-desc {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .buy-recharge-btn {
    width: 248px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    border-radius: 17px;
    color: #376ff7;
    font-size: 12px;
    margin: 0 24px;
    border: 1px solid #376ff7;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 12px;
      height: 12px;
      margin-right: 6px;
    }
  }

  .no-recharge-btn {
    height: 44px;
    margin-top: 30px;
    line-height: 44px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #376ff7;
    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>
