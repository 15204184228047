import Cookies from "js-cookie";
import store from '@/store/index'
const TOKEN_KEY = "auth_token";
export function getToken() {
  return Cookies.get(TOKEN_KEY);
}

export function isLogin() {
  const token = localStorage.getItem('auth_token');

  if (!getToken() && !token) {
    store.commit("setIsShowLogin", true);
    return true;
  } else {
    return false;
  }
}
