import axios from "axios";
import { getToken } from "@/utils/auth";
import store from "store/index";
import { MessageBox } from "element-ui";
import Vue from "vue";
const clearRequest = {
  source: {
    token: null,
    cancel: null,
  },
};
const cancelToken = axios.CancelToken;
const source = cancelToken.source();
let stateAxios = true;
const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${process.env.VUE_APP_BASE_API}crewordapi`
      : "/crewordapi",
  timeout: 300000,
  cancelToken: source.token,
});

myAxios.interceptors.request.use(
  (config) => {
    let TokenObj = getToken();
    TokenObj = TokenObj ? JSON.parse(TokenObj) : "";
    let Token = localStorage.getItem("auth_token");
    config.cancelToken = clearRequest.source.token;
    if (TokenObj || Token) {
      config.headers.Authorization = TokenObj
        ? `${TokenObj.token_type} ${TokenObj.access_token}`
        : `bearer  ${Token}`;
    } else {
      config.headers.Authorization = "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

myAxios.interceptors.response.use(
  (config) => {
    return config.data;
  },
  async (error) => {
    if (stateAxios) {
      stateAxios = false;
      if (store.state.exampleLoading) {
        await store.state.exampleLoading.close();
      }
      stateAxios = true;
    }

    let code = error.toString().split("code")[1];

    if (code == 401 && store.state.tipsSwitch) {
      store.commit("setTipsSwitch", false);
      if (store.state.isShowLogin) {
        return error;
      }
      MessageBox.alert(
        "登录过期或在其他电脑设备上登录,请重新登录",
        "登录状态异常",
        {
          confirmButtonText: "确定",
          callback: (action) => {
            store.commit("setTipsSwitch", true);
            store.commit("setIsShowLogin", true);
          },
        }
      );
    }
    return Promise.reject(error);
  }
);

export default myAxios;
export { clearRequest };
