// 写手icon
export const LOGO_SVG = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">  <image id="image0" width="96" height="96" x="0" y="0"
href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAT
rElEQVR42u1deZQTVbr/fbeq0p1Od9MsjeygHlRAEFkcEEREfSoefeoRR33q4AYqguKMqEcd81gE
BxSVbUBHBEUdHNE543GeznNwQAefynnIJrwRcGNRtqb3pKru9/5IqlKVVNJJd6UbZvo7pzpVd6ub
3+/e33e/m+oEaLVWa7VWa7V/VaOW7sCJanM+i/SThrgJhDEA+gIojmftJcYmBv9JBLQ3Hh5CxzK1
00pAjjZ7Q10vJnU+GFdlUfwYMZ5qF1DnTRxCuleBVgJysJl/N64m8EokRnu29rkq1aseGkn7kjNa
CcjSZn1iXA/wKgCicS3QLpjKeY+Oov2u1JZ+Yw3Za79c2iEQ1J8rOnTogqKKo+0DkTpVi0ZIDxRw
pLg4Wt++/Y9mefmak3uXPdzvuuui+ejD9PXRwQLYAEBrYlOflge1UU45Om4JWP74sqHtDuxd02v3
jm4Fht5g+epQMQ72O3Ozdtrp/3bRnf/+o1/9CK9lVSF9Ewj9/GiPQI88dr42J3F9HNrq++b++bSt
Gy8tNHIf0MfatOFDI0fMvXzaHQ/50Zfpf43ewoQVPr69Y1yn9QiPpUrgOCMgPC4cGBzSv+u956uT
mtIOE+HbESP+fumsB0Y0uU8fRj8BcK6f75OACU9cGHgBaLRD8d8YoKGFdfubCj4AEDN6ffzxuR88
PG9DU9oJr+VCafJwaTL8PEyDL7fucdwQ8NZdMz879bt/tPOzze6fbhj2/uwXnmtsfT1aVy5NkDQB
Xw+JwdY9jgsCXv3Vgpv67Nw0xO92iRnl69dN/tuC1Sc3pr6sU0qkwcjD0c26h9qSwFvWafeOFxUp
89J2UU0NVe7a8RGAnrnWlQJERn7fe4vPgJXjw0u6Hd5fkM97nLTpyx5fPv3ylbnWk1GOmgYjD0et
dY8WnQHzbgh3OH3/rgn5vg8xQ27d+gqANrnUk9HoUaa8QLTDOmnRGdAjWrmmTX1Ns/QhuHt36ZYZ
v/1NLnWevqHksKnjsKkDPh9/s+7RYgQsvDE8qu+R789rznuq/7tx6rYXV2e/0iJiM8q/N6MMXw+d
X7P71JwAOK1H9eHXiQEGgcDNck86fFjlr799C8AF2daREWM+C2UC/MPqoyV3F39hXbTIDFj0HzOn
da+u6GKQBkNoMEkFN1NQrm7cOHrLc69kHdkuntTmaz2KeUaU4cMR1SO4z9WfZsYe4fHhwlMPHZyu
kwYFAgQJBSYECQiWEGzmd0ZEoxA7/+/3ALpnW0UUhn6tV1aNANBEyaQpyx8s3uxqO3/v1Ns61mmv
F0eiBQap0IUGgzTozkNoMEgF57FryrZt3TbPfnFqtuWXTSS92jSuMOqxwYgAjTnMKKYtf7BkaXLb
zboZ9+Stz/cd9OM3WwsNnQRLCMRGfOzVcW2fSwjErv022blzffCSIW1PvvXW+mzrjA9zYb1ZOZeA
STlg9xOYJr4+s/Qdr8xmnQHta2reFlKQpf0GqfHXpMNOU+00Sf52VezfX1i1u/KVXOq8HKb6N2a0
mWzUy0F6hN/UIxzVIwzvA/v0CMJG1DwtHfhAM86Ambcs/sXAQ3tfjul8YmQ7Rz+xCYVlzC+wCYrn
KSnlTV/6xEVFrI8d23fA5Bt3NKb+uDAHZE1lTzKNcggRAABpUCWg71/zbIcDADXozJqFgHA4LLpt
CR3rUF9dnAx6wvFKT+nxlCrHeVMdtnH22V+d+ezjfTOVGR/eU/hy+OSspSoXaxYJUnd3Xtomohen
SE1cZnSRmpYqR94SZZLSpCWsumlTn81zl9+YqUx1ZdsRV0zYV5QPbPJOwKO3rOjauar2NkOocWCT
DwfoVhmRTIbq6RcMEUCTYwlmqFu3/JbD4bRYmOB+SpuizickAUXSeFuVQhikwXSOXhtsFaaLiGRC
UmeLHm9HJ/cyNkFEbm9LfPNNyVZRvihdPjH3E3puG3nHBQEPjl81trxWH+oc8WbKyHfKimMWJOUl
YoQE6G75Uh2xhJpzLKFu3Hjn9vmveI9yonOFyqX5wCivkXBZ1FwhoQBEYEhIxB1q0nkiTSSuKeFs
yS4TzydrReQsH4+kSUJwLF1S9rEEVVQoxv59awAMd6aPm3qsnQnZjyXnxQfkjYBf3b56ZmlFXQeD
VBskSgHcfU0ppIg4oElg2kTE08i0QU+uJyBB7cuB/v3A3TqDmEFHj0LZtQvKjh0AJ1ZR2saNw7Y8
+8rF/e+/+S9WmklyJAACUeCEIWDy5PdK2x2qnGaBb49+kmCWkMSx0U0MyW7QEzMlvtQEQ8BMkGON
dLtOjBCChELxWCJ+Lbp1grjhamDQWQClOmiqqoL2/vsIvPsuqLoaMAyI7dtXAejoKJbzJ2m5WF58
gFZd/wfVVDTTcrDCrf0mKY5lpArT4ZAN+9rhFywHnmbFpFPM+erxsrrQwJddDDH7cWDwQE/wAYBL
ShC99lrULFwIfcwYAICyc2f5ltlLnwCAcJgFgCsAgJiq8oGV74HY3Xe8O7hrtfGFEg+SElGu9cpJ
0W8i+KI06Xa+VxCHpECOJYpuvhKBS3LfuFQ3bEDhokXg0lKdR49p99jXFw8mwkexXDl8zTPtP/Ub
L98lKGhgjYQKUAwwJmFrvbRlRiCdQ5YpcsS2HJEtXTJJhuKSAxOhq0c3CnwAMIYPR23nziiaNUuT
e39YTYSDVh4x8jIDfJWgOye+P7k4qvUwLWkhFQYpcF6bjuWombI0TY0JnNexOumXrnr/0xG6+qIm
vQfZqxdqZswA7dlzSZfAsZ9b6bosOJQPAnyToHHjVge6FbWrCBoUTMgJ29JA8XHvlhz2kJ74iHfU
9ZKk5GuzuACnzL4LIlTY5PfCAOiHvah84wMs0q8BAGPNM2WBbDbXcjXfZkBpWfsVmhkImqTAtEe9
c/QrHrNA8ZgNbgdteMwSr6PtbZf7Bj4zILt2RcllwzEw9D0A7MsH+IBPPuAXEz48tTgS/LlJDMEE
ppgmM6xXAkHElpxEdsDFiM0CZ5Am40vP1LT0PsIY0BW9B/b2BXjXeZ8zMPToV9i8jnfnA3zfCBCy
4I9glUxKgE4swCRBEHGg3edORxzLcV5zCuB2IOaKJSSMIoEBt1/aJODBcdCtNMd1ybAzMCp6RP1D
nghosg+48e7149rWhlYn9JzjBMQ135nOsdFtL0mTfIDndRq/YaWHxg1Cn4sG+g68M0/XUa1J6nFe
TzrqNwFN8gHh8Fq1KNDmJZMUyCTdt6+R7A8cPgKKvUpK9gmZfUTsvKZTaaPBd4LN7LiOn0vHuaKi
2AzI//Qb/CYTcLDs5HWiViuWpMSBjh3ScW4frrRMDjp12Zq8XLWi69435fYPMMlgy6Rz6UGClS6Z
7ll7gM/0m4BG+4CJz3/bV4lqw+rMehCUWJAECWICU8ztEjMEWbofd7wsQGQ55vhfV5pzX9TaiIvV
TvgPCfTrgC69s/uMJBe5cc4EuNMUKXkBcniqLhtr9AwIFgU31P9kkIzLj3SMdneagInUfGu0y2RZ
8pIqW65i55GAhnNuHp5VP73kJt1Id8kQHKPfOpcY/cEPPK7FCbhnwff3hToUl9YdMSFhgS3scwt0
M5kcuM+9QM5GnrSBHXeESoLZAe8hN+wAljk94F7+QTLPW/09B+GTNYqAYLvSZ2r2R2FCxMB2AS4c
pCT5hqSRLm3Qk2YAUmeFNfprgqJmwJj+o8A43BDwEkkjGt7ONmX0s5ukhB8AJKNHsZQPtxgBdy3Z
90FJp6CoOWgkpAYiBryDiBiQwlHG7ZBlkpOWGYhySlJVGU0dO6jkIIgeTQs+GpabFHKSAYe3LMXa
oGlv7+FezU7AhKX7OrTtUnYxANQeNRMxqQPkhByJJOkRjjLCJUNuMhygJ5FSVUq7Fj9z6QsAMKQD
XgCwyQJe5ig37B7V3svR9MQUCuKnm50ATdG2FpSoYAlEahHbaibhIEIkSVAiPQG4sEe8RUq6Jawz
ltAVhetC8hqrL0QkIeleF2hwy02mZWXKqPaWm5Q0R51r3tnFTdt6zYWACYv3XdWhZ+lJAKDXJ3Zk
2AV8OiKcMuUtR8npybFETan53ovzLnI92j30JPrEZH6tQceabvQjFeRkYtLOCAYk5HNr13KTtnOy
JiBYElot1NjOhdThAppTgPe4dsmUgwxKzAozjb/QAxRVTfN6r36xIqYxozpdFJvQbfeo9gKXGxj9
btIYJtD3UA95b94JmLjkx+XtugXtr2ohlcBELuDZi4gkB+2aBUky5FrCJsUTdSFj/uLFF1R79e3c
DrSXmWZljGI9RnVmcDMtSTl+2DMivPJr7tgQho0mYPzyPYVlnUrGO9O0gjj4FCNCJhFhy08aB23G
P2R0+wMlxX+YUGAIxQzUaY9n6mNFFeZL4Ou0q5cM4HotP72XpByLwVPJa6OxfDJvBBSaJZtDbd0y
J1QgUJIY8UwU/+w34ReYKAu/ECMpOZZwkSLw7bJlQzJ+YdDY3hQh0NSMq5c04GYc/ZyQm5Q0Rz2T
cdvKHdyor1rISMDdK6oHlHcv8fyko+Sk2FPJnNg09iCCID2I4IxyFH/axyJCalnJ5Hmd6F3J+HPG
KBbZAu4EnhuWKQYxmQuYOeft/YxvrkvPwHKt0LvNjqeoccApIxFsE+F20N5LWM9Yotf4CXvOyObN
MNNUKWE2tKxMKzfw1vm0MuVabdGw5TvMW3wjYNY643ImDEqXHywllJ+iADbgXkQkQGbr2TZXWnZL
WMOUzwCZR9cdc6tGv/p69YvbtkeVTFEsZys3yDQz0sgbaM6r/8jtId60b2rGuughZrSPDS3H9qwd
8QCmydixzkDNEXb9pwrF93cpXpDijRAnytl02Wnua0q9fpXr9EmrVvWutO4TDrPY17b6ESkxiSU6
MwOqAoy9vAiFQfIM0FJe7XPOkJdNfZvcuRP7KtOaRMD09ZHZxPSw/dxqGgIYDKkDuzeaOLpX2oXJ
bjwNEeD4MwZuoOENvPP6iCB+RxRp1Wp5wZVKcUF3JigsAZZxICSjR08VPxte2ND+firwDQAusyjD
gC4N0f+es2hnowgIr+UyRdEPAlCzIcDKqzig895tRHWViUreRCBpxKcjAvEP8gFiCbWAoJVp0EpV
KCEt1oKMAZ5MgJTAhRcH0a6DkgEobtQI9yQlpSz916QzxWWNImDGx9EPITHGBjtLAqz0SK1hVByI
RCoPslZboQWMeuGQJyfgbiIsgggMQQytiBAICWghgUCJAhEQNsAJsNMTUFYmcOGlRdnLTRNkx5MM
yVdOOUv9U04ETF8f/RkBn8IJdo4EJF5jeVKCDV3qRj1H9ag09Qib0pCx3hIgFBAEkaJAUTWhCSEC
IBIxQJPAzoEAlsDZ5xSg16ma3e9McpMSlGVLhked+DJ4twiKvlN6UyQTAa4ISwBvMvw1IpCqiYCq
IlDAig2SkzgLbDiA9sO2bYqiSzcVaiBLUD3SZA5lk15PMWvlAwBmZ+qjvQyd8Un0TuTwBRYngkXq
Gds3R7JfVnocXjMlbVTsqBO3R+d+mXmfyCaAmObgn9B279Rx7KjMGvCGyqRsYTjKekzckAr5YKb+
CQB4cp1+PgBfv7PzeDFmYMsXkQai2OxGtuvjTOfsyCyZt4a3cdr/LxMAwArub2mg8mmHDpjY+52R
OYrNRAqykpt01r6NYYxMlxmTIMY5LQ1Svu2rjdEjkhHJaWshO51v2FikfYQvNgMQ33L457VD9VU0
2gTm5Co3yc63UQs0Qp90WZYTbuoPExzPth9CGbNqRvGWUJF4ihnfcgP6ns75Nt6oPF2ORcBx9TX2
PtpeKXnkql8XbwGA67pTHYN/mfapCD/kxtNk2gFuEdDwT1SceFYhDXXYa+Ey13+33N5Hfctk+qvn
8hINLisbZ0Rpf9HDIqAyy6ZODGNsLoxWd10VLvrBK9sATZGAmcn5+mkEpP0XJ4uA7S2NmU/GBFqw
4pHSs5aFu9SmK3RvP9omJRblR248OiX583R5cQLopZZGzgerlJLPf2layZRsCuuGeEIyDvouN6kW
jRYqf0mXKQDgsZHqCgB5+U60ZjAm4J3KquLyl6eVrs+20tSzqYLBj/otNx6dW/3QGem/ZyKxFwT6
XXOg5bPtI5bDX5hacvWbYcr5J5cq+iu/A7Axj/1jhcVTmQrYBOi6cj9OEGdMQA2IpyybXNx12f1t
/qex7YSJJAtxFwB/vgcztZ9L7juLtmYqYxMQvoAMwXQl8iqHTbZaYsxZcneoZOk9JQv8aPCB/vQF
EcJ56Os2TRUN/pZZSgA2c310CjOeA/z5RCw5L9sPZJI+ETsI5nkLbw/NBfn/lQHMTPO3yJXEuMmn
JveCxKipA6jB/7BPeS7osfMCzzNhEmLL45Y0E8DHDHnhwttDHRfeUfybfIAPAETE3XaI8Qw839S2
mLA9W/CBDFsQ09dHB0PiPQAdm3EGGCyxTUq81LZ7cHH4gnz/hlGqPbvZGMdMiwCU51iVCViiqeKh
Sf2oOttKDe4BTf9InwbwNOshLZ8JkMzYx5I+kzBXzr8+9MfmBtzLFm3j4qgh7wNwF4BuDRSPAniL
Scx+YABtyfVeWW/ChddGBwKYBMZQMLowowjgIBiiAQKYGToYtcz4iRm7wPy5Kfm9edcWNXoF0xwW
ZhZlmzEUkKOY0ZeIOgEoYOAQmPcQ8Wf1uvLfDf1suS8EZOzoe1wKpbpQjyr2rp+mBKvwGarDYWpp
X9JqrdZqrdZqrdZqrdZqrdZqbvt/XQ8pGeVO6NoAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMTEt
MjhUMTE6MjU6MTQrMDE6MDBey3a6AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTExLTI4VDExOjI1
OjE0KzAxOjAwL5bOBgAAAABJRU5ErkJggg==" />
</svg>`;

// 撤销
export const UNDO_SVG =
  '<svg viewBox="0 0 1024 1024"><path d="M512 64A510.272 510.272 0 0 0 149.984 213.984L0.032 64v384h384L240.512 304.48A382.784 382.784 0 0 1 512.032 192c212.064 0 384 171.936 384 384 0 114.688-50.304 217.632-130.016 288l84.672 96a510.72 510.72 0 0 0 173.344-384c0-282.784-229.216-512-512-512z"></path></svg>';

// 重做
export const REDO_SVG =
  '<svg viewBox="0 0 1024 1024"><path d="M0.00032 576a510.72 510.72 0 0 0 173.344 384l84.672-96A383.136 383.136 0 0 1 128.00032 576C128.00032 363.936 299.93632 192 512.00032 192c106.048 0 202.048 42.976 271.52 112.48L640.00032 448h384V64l-149.984 149.984A510.272 510.272 0 0 0 512.00032 64C229.21632 64 0.00032 293.216 0.00032 576z"></path></svg>';

// 清空
export const CLEAR_SVG = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">  <image id="image0" width="96" height="96" x="0" y="0"
xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAd0SU1FB+cMAQEwEQ7td8gAAAay
SURBVHja7VtbbFRFGP5mFyotvXCRawFjSNC0AaFnF6hCrAQhSsXy4ItGjQRFCRiBSOIl0YAGomiQ
KCZEVB5MjGKQu1zkYiCAdLelICIWKAUslGtLL0vbc8YHujNTODNnunt6tk32e/r+Pf9cvnPm+s8s
kEQSSXRpkI7K2Miysklqm5+a/dVHLnUJAYHpdBHGw2/zqJysavjqRFNnFkDyPiPzlR77/UV/XnOr
OJ/b9Tfed6g+MMH8uaCbW+X548+iTfWH4yeNPB9sOl8VdqdEl78AmYnuWo6z3CrRtU/ZijzGdvuW
3v3QmoR3WqkBAtoJBdB0Ri8d2XX3U6M/L9fIDNW4UaLrndhrJAUkGkkBiUZSQKKRFJBoJAUkGl1e
gPZiLphrziMTMcjBLUO75ApDvRqtRRhrQlvcEUAC71ofEndXrr0cnvfGA5hhbO7x/IFbKjetJmQs
ph+5vnPQQ2Fki6HcImkIyBvFtiHtQeW9P9FYgioTMS9OAWRuDDvnSqy898fwHmyNQcKbqtiJc1jF
Z1QhupP6G2u1iqwxfyy9afegoFtdER2ulcd4FHEeOixzc2zZgXzKN4Lfhpbrvzg77G3BOj1PY6Qg
YBqkAhybEC0UjM3xVb89CB0TetE0uZ9zH+CJT4dOeicAIJsYzTOGxSjAGI6RzNjgZfUBulEwpN/A
6QuICT1sQABQsw91bgqowX5vBZTfJjuYMTkn3d5LKcDIwhPM2Bpq9lZAm0Z0X+rkGARgihDp9LgB
AUDzNljMkDQitQCeyEz5zXsBZdWEj//T7euqEPCcH3wO+OPgde8FtGlE/Y1gOwWcCaAvMxzX5R0E
sdyp7RQghMoTJiB0DGdt66MloCdjJ72dg9uAz8e2uz3VYu4cY73zdiZMwOAooRXtFGDu9NfhzvQx
gAxImACOX+1+VDSh0ptYkeg6CzgUtu2HynmgcQn5PdH1bsUF+qIwqQlQbhavmIPWIRujE117HMcz
4VP2jzRO6oNBax+itx4qaHnH15cMwcPMWEs3ZG7a2yL11cnQuImsVro89HbHCzCmYDszpoZ2qHz1
QosRxnpq+ceJ5lLO6SNqXz0Bt72oNkdZNVgEiTzkhgDPQf5i1CEI00kF4DRjg9WOnVQAvcjoQDcE
sAUHsbT84wa5zGhmfqrKU08AG3voLS3/uGHd4DzSX+XZSZuQX9j/+TK7oACLzzygyslWTwCLTZA6
Lf/4IYRwiAtfII29DdMjAQ2cWso6aggw0gSj3iMBAojyMFDj5IsMFXKQHBLlzSAj7jB6IfyDLKfA
GDolymtWlKsWKMJLi1sAHSXwMxKRb+DJVrYDUgF0PJZFeb+VynW53t1H6PWBiYy11B6XCOjNqOIq
n3AlEAcbldUSN1rKy4E6Ap5mrFj22fkxFFVE8ITL4BEoYQojj1+5FnYUkGfw9SCVxEdzUpDNKlmh
yKwPYzegBBFiQLQ2LgFkpuC80d4nbQTfW8t6CQCANzSnSGsWp7fjOakf3QsvMeN4cYm9lxXg3H9K
kR2/aVGtLcAsUw7dDgL8s8E73vcyLzKO0Uj9CUV2rKGhykHA/Yw5XNRXCngsAwuYUWeukTsyKSXK
PzcMYX4XoQaPi8cjIDIffCn7nf3ZO2AM4yeZ1iF5bsGBYGOLfZxTQD/GLqsdFQKCA7GQGU3m51LH
IiG7XfL8xCsG5F9dAcShuysEWMv4G8OaUvk7e4Gx+u57FPnlcm6qujoAsGAydejuUgHBR4Xxp6Hl
Y6lfLsYyY6tqfiVjGL1VUgk1+Hjl0N0lAnJSrNU8akc/OSrtdNZswVDfZeEnLEfVf34Y0w8pTLZD
d5cISF0E/sEryaey5Pl98Ap/VxnbIUVBDyFIXAI1xFDKfzEIyO8DIQJKFoQaZMmbF/J5gqyWh2CB
2iB/q1CMVQBAhgqGwy0vWwFNc4Tuu6H4F1nicUPoW8xopF8qS5rEqXVAXSlh/YuWs2pX+/0AL6y+
21x54pYlwsbjm9BVZUlsK4NzJedUjsYgvMaMM7LZJwr7PsAmclp8+IIsaaAALzOjDkuhwNi+wnJD
GS4PDsU24UrmejjA/gucj86tJN+Y45OM2dYq4XRhly83mAspzMeFs6DrQcnFDTODTLBmCc23CV87
CbCNuRivOyf0BItDH8QkICc99bzjzdqOx87GQuf/vdoe8l1pGnwN0xNc/d14tizi7CY5pawKZ/fk
i2TP0YilGa8e1DoXUsQdA0/R95DvefT0H6z3faH/z2+HU8qc9LRsK10vq/hBr/quyGf9JJJIIokk
OgD/A+G2oQ+pMVAVAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTEyLTAxVDAxOjQ4OjE3KzAwOjAw
/cqzdwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0xMi0wMVQwMTo0ODoxNyswMDowMIyXC8sAAAAo
dEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMTItMDFUMDE6NDg6MTcrMDA6MDDbgioUAAAAAElFTkSu
QmCC" />
</svg>`;

// 标题
export const HEADER_SVG =
  '<svg viewBox="0 0 1024 1024"><path d="M960 960c-51.2 0-102.4-3.2-153.6-3.2-51.2 0-99.2 3.2-150.4 3.2-19.2 0-28.8-22.4-28.8-38.4 0-51.2 57.6-28.8 86.4-48 19.2-12.8 19.2-60.8 19.2-80v-224-19.2c-9.6-3.2-19.2-3.2-28.8-3.2H320c-9.6 0-19.2 0-28.8 3.2V780.8c0 22.4 0 80 22.4 92.8 28.8 19.2 96-6.4 96 44.8 0 16-9.6 41.6-28.8 41.6-54.4 0-105.6-3.2-160-3.2-48 0-96 3.2-147.2 3.2-19.2 0-28.8-22.4-28.8-38.4 0-51.2 51.2-28.8 80-48 19.2-12.8 19.2-60.8 19.2-83.2V294.4c0-28.8 3.2-115.2-22.4-131.2-25.6-16-86.4 9.6-86.4-41.6 0-16 6.4-41.6 28.8-41.6 51.2 0 105.6 3.2 156.8 3.2 48 0 96-3.2 144-3.2 19.2 0 28.8 22.4 28.8 41.6 0 48-57.6 25.6-83.2 41.6-19.2 12.8-19.2 73.6-19.2 92.8v201.6c6.4 3.2 16 3.2 22.4 3.2h400c6.4 0 12.8 0 22.4-3.2V256c0-22.4 0-80-19.2-92.8-28.8-16-86.4 6.4-86.4-41.6 0-16 9.6-41.6 28.8-41.6 51.2 0 99.2 3.2 150.4 3.2 48 0 99.2-3.2 147.2-3.2 19.2 0 28.8 22.4 28.8 41.6 0 51.2-57.6 25.6-86.4 41.6-19.2 12.8-19.2 70.4-19.2 92.8v537.6c0 19.2 0 67.2 19.2 80 28.8 19.2 89.6-6.4 89.6 44.8 0 19.2-6.4 41.6-28.8 41.6z"></path></svg>';

// 加粗
export const BOLD_SVG =
  '<svg viewBox="0 0 1024 1024"><path d="M707.872 484.64A254.88 254.88 0 0 0 768 320c0-141.152-114.848-256-256-256H192v896h384c141.152 0 256-114.848 256-256a256.096 256.096 0 0 0-124.128-219.36zM384 192h101.504c55.968 0 101.504 57.408 101.504 128s-45.536 128-101.504 128H384V192z m159.008 640H384v-256h159.008c58.464 0 106.016 57.408 106.016 128s-47.552 128-106.016 128z"></path></svg>';

export const COLORS = [
  "rgb(0, 0, 0)",
  "rgb(38, 38, 38)",
  "rgb(89, 89, 89)",
  "rgb(140, 140, 140)",
  "rgb(191, 191, 191)",
  "rgb(217, 217, 217)",
  "rgb(233, 233, 233)",
  "rgb(245, 245, 245)",
  "rgb(250, 250, 250)",
  "rgb(255, 255, 255)", // 10
  "rgb(225, 60, 57)",
  "rgb(231, 95, 51)",
  "rgb(235, 144, 58)",
  "rgb(245, 219, 77)",
  "rgb(114, 192, 64)",
  "rgb(89, 191, 192)",
  "rgb(66, 144, 247)",
  "rgb(54, 88, 226)",
  "rgb(106, 57, 201)",
  "rgb(216, 68, 147)", // 10
  "rgb(251, 233, 230)",
  "rgb(252, 237, 225)",
  "rgb(252, 239, 212)",
  "rgb(252, 251, 207)",
  "rgb(231, 246, 213)",
  "rgb(218, 244, 240)",
  "rgb(217, 237, 250)",
  "rgb(224, 232, 250)",
  "rgb(237, 225, 248)",
  "rgb(246, 226, 234)", // 10
  "rgb(255, 163, 158)",
  "rgb(255, 187, 150)",
  "rgb(255, 213, 145)",
  "rgb(255, 251, 143)",
  "rgb(183, 235, 143)",
  "rgb(135, 232, 222)",
  "rgb(145, 213, 255)",
  "rgb(173, 198, 255)",
  "rgb(211, 173, 247)",
  "rgb(255, 173, 210)", // 10
  "rgb(255, 77, 79)",
  "rgb(255, 122, 69)",
  "rgb(255, 169, 64)",
  "rgb(255, 236, 61)",
  "rgb(115, 209, 61)",
  "rgb(54, 207, 201)",
  "rgb(64, 169, 255)",
  "rgb(89, 126, 247)",
  "rgb(146, 84, 222)",
  "rgb(247, 89, 171)", // 10
  "rgb(207, 19, 34)",
  "rgb(212, 56, 13)",
  "rgb(212, 107, 8)",
  "rgb(212, 177, 6)",
  "rgb(56, 158, 13)",
  "rgb(8, 151, 156)",
  "rgb(9, 109, 217)",
  "rgb(29, 57, 196)",
  "rgb(83, 29, 171)",
  "rgb(196, 29, 127)", // 10
  "rgb(130, 0, 20)",
  "rgb(135, 20, 0)",
  "rgb(135, 56, 0)",
  "rgb(97, 71, 0)",
  "rgb(19, 82, 0)",
  "rgb(0, 71, 79)",
  "rgb(0, 58, 140)",
  "rgb(6, 17, 120)",
  "rgb(34, 7, 94)",
  "rgb(120, 6, 80)", // 10
];
