import { BOLD_SVG } from '@/views/pcMarkdown/config/wange/constants'
import { MarkdownEditor } from '@/views/pcMarkdown/editor/utils/node';

class BoldMenu {
  constructor() {
    this.title = "加粗";
    this.iconSvg = BOLD_SVG;
    this.tag = "button";
    this.key = 'bold'
    this.active = false
  }

  isActive() {
    const mark = MarkdownEditor.getSelectionMark(true)
    const markKeys = Object.keys(mark)
    const hasTitle = markKeys.find(key => key.indexOf('header') !== -1)
    const isFocused = MarkdownEditor.e.isFocused()

    if (mark[this.key] === false) {
      this.active = false 
    } else if (hasTitle) {
      this.active = true 
    } else if (isFocused) {
      this.active = !!mark[this.key]
    }

    return this.active
  }

  getValue() {
    return "";
  }

  isDisabled(editor) {
    return editor.isEmpty();
  }

  exec(editor) {
    if (this.isDisabled(editor)) {
      return;
    }

    const mark = MarkdownEditor.getSelectionMark(true);
    const markKeys = Object.keys(mark)
    const hasHeader = markKeys.some((key) => key.indexOf("header") !== -1);

    if (hasHeader && mark[this.key] !== false) {
      this.active = false
      MarkdownEditor.updateSelectionMark(this.key, false)
    } else {
      MarkdownEditor.updateSelectionMark(this.key)
      this.active = !this.active
    }
  }
}

export default BoldMenu
