import { HEADER_SVG } from '@/views/pcMarkdown/config/wange/constants'
import { getHeaderType, isMenuDisabled, setHeaderType } from '../helper'

class HeaderSelectMenu {
  title = '标题'
  iconSvg = HEADER_SVG
  tag = 'select'
  width = 60

  getOptions(editor) {
    // 基本的 options 列表
    const options = [
      // value 和 elemNode.type 对应
      {
        value: 'header1',
        text: '标题1',
        styleForRenderMenuList: { 'font-size': '28px', 'font-weight': 'bold' },
      },
      {
        value: 'header2',
        text: '标题2',
        styleForRenderMenuList: { 'font-size': '24px', 'font-weight': 'bold' },
      },
      {
        value: 'header3',
        text: '标题3',
        styleForRenderMenuList: { 'font-size': '20px', 'font-weight': 'bold' },
      },
      {
        value: 'header4',
        text: '标题4',
        styleForRenderMenuList: { 'font-size': '16px', 'font-weight': 'bold' },
      },
      {
        value: 'header5',
        text: '标题5',
        styleForRenderMenuList: { 'font-size': '14px', 'font-weight': 'bold' },
      },
      { value: 'paragraph', text: '正文' },
    ]

    // 获取 value ，设置 selected
    const curValue = this.getValue(editor).toString()

    options.forEach((opt) => {
      if (opt.value === curValue) {
        opt.selected = true
      } else {
        delete opt.selected
      }
    })

    return options
  }

  isActive(editor) {
    // select menu 会显示 selected value ，用不到 active
    return false
  }

  /**
   * 获取选中节点的 node.type
   * @param editor editor
   */
  getValue(editor) {
    return getHeaderType(editor)
  }

  isDisabled(editor) {
    return isMenuDisabled(editor)
  }

  /**
   * 执行命令
   * @param editor editor
   * @param value node.type
   */
  exec(editor, value) {
    //【注意】value 是 select change 时获取的，并不是 this.getValue 的值
    setHeaderType(editor, value.toString())
  }
}

export default HeaderSelectMenu
