const user = {
  namespaced: true,
  state: {
    userId: null,
    token: null,
  },
  mutations: {
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('auth_token', token)
    },
  },
};

export default user;
