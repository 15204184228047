import crewordRequest from "./request/crewordRequest";

export function pageEceData(data) {
	return crewordRequest({
		url: "/wqShenLun/pageEceData",
		method: "post",
		data,
	});
}

export function queryShenLunType(data) {
	return crewordRequest({
		url: "/wqShenLun/queryShenLunType",
		method: "post",
		data,
	});
}

export function eceTopicMore(data) {
	return crewordRequest({
		url: "/wqShenLun/eceTopicMore?esId=" + data,
		method: "post",
	});
}

export function queryeceDataALLAndToDayCount(data) {
	return crewordRequest({
		url: "/wqShenLun/queryeceDataALLAndToDayCount",
		method: "post",
		data
	});
}

// 金句收藏
export function eceCollect(data) {
	return crewordRequest({
		url: `wqShenLun/eceCollect?contentMd5=` + data,
		method: "post",
	});
}

export function toDayQuestion(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/toDayQuestion`,
		method: "post",
		data
	});
}

export function saveAnswer(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/save`,
		method: "post",
		data
	});
}

export function answersList(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/answersList`,
		method: "post",
		data
	});
}

export function deleteAnswersRecord(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/delete?id=` + data,
		method: "post",
	});
}

export function shareAnswersList(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/shareAnswersList`,
		method: "post",
		data,
	});
}

export function shareAnswers(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/shareAnswers?id=${data}`,
		method: "post",
	});
}

export function myShenLunNum() {
	return crewordRequest({
		url: `wqShenLun/myShenLunNum`,
		method: "post",
	});
}

export function aiAnswer(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/aiAnswer`,
		method: "post",
		data,
	});
}

export function queryAiAnswer(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question-answers-track/queryAiAnswer?id=${data}`,
		method: "get",
	});
}

export function getQuestionList(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/getQuestionList`,
		method: "post",
		data
	});
}

export function collectBigQuestion(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-collect/collectBigQuestion?id=${data}`,
		method: "post",
	});
}

export function cancelCollectBigQuestion(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-collect/cancelCollectBigQuestion?id=${data}`,
		method: "post",
	});
}

export function getQuestionsMaterial(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-question/getQuestionsMaterial?id=${data}`,
		method: "post",
	});

}

// 获取购买申论规格
export function queryShenLunProduct(data) {
	return crewordRequest({
		url: `order/tps-order/queryShenLunProduct`,
		method: "get",
	});
}


export function myAnswersQuestionList(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/myAnswersQuestionList`,
		method: "post",
		data
	});
}

// wqShenLun/shenLun/save
export function shenLunSave(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/save`,
		method: "post",
		data
	});
}
// wqShenLun/shenLun/uploadFile

export function shenLunUploadFile(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/uploadFile`,
		method: "post",
		data
	});
}
export function takingCharacters(data) {
	return crewordRequest({
		url: `wq/my-business-card/takingCharacters?fileUrl=${data.fileUrl}`,
		method: "post",
	});
}
export function extractWordDocumentContent(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/extractWordDocumentContent?url=${data.url}`,
		method: "get",
	});
}

export function finalExpansionResult(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/finalExpansionResult`,
		method: "post",
		data
	});
}

export function getExpansionResultByExpandId(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/getExpansionResultByExpandId`,
		method: "post",
		data
	});
}

export function listExpansionHistoryRecord(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/listExpansionHistoryRecord`,
		method: "post",
		data
	});
}

export function listExpansionHistoryDetailById(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/listExpansionHistoryDetailById`,
		method: "post",
		data
	});
}
// wqShenLun/shenLun/deleteExpansionHistoryById
export function deleteExpansionHistoryById(data) {
	return crewordRequest({
		url: `wqShenLun/shenLun/deleteExpansionHistoryById`,
		method: "post",
		data
	});
}

// /wqShenLun/myEceDataCollect
export function myEceDataCollect(data) {
	return crewordRequest({
		url: `wqShenLun/myEceDataCollect`,
		method: "post",
		data
	});
}
// wqshenlun/twq-shen-lun-collect/collectionList
export function collectionList(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-collect/collectionList`,
		method: "post",
		data
	});
}
export function nextEceData(data) {
	return crewordRequest({
		url: `wqShenLun/nextEceData`,
		method: "post",
		data
	});
}
// wqhome/twq-topic/praise
export function praise(data) {
	return crewordRequest({
		url: `wqhome/twq-topic/praise`,
		method: "post",
		data
	});
}

export function addEceDetail(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-detail/addEceDetail`,
		method: "post",
		data
	});
}

export function getEceDetailList(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-detail/getEceDetailList`,
		method: "post",
		data
	});
}
export function eceAssemblyList(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-list/eceAssemblyList`,
		method: "post",
		data
	});
}

export function ecaDownload(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-detail/ecaDownload`,
		method: "post",
		data
	});
}

export function renameEceAssembly(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-list/renameEceAssembly`,
		method: "post",
		data
	});
}

export function deleteEceAssembly(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-list/deleteEceAssembly`,
		method: "post",
		data
	});
}

export function deleteEceDetail(data) {
	return crewordRequest({
		url: `wqshenlun/twq-shenlun-assembly-detail/deleteEceDetail`,
		method: "post",
		data
	});
}
//查询用户提分记录
export function queryUserScore() {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-score-record/queryUserScore`,
		method: "get",
	});
}

//获取申论视频
export function getSHEN_LUN_VIDEO() {
	return crewordRequest({
		url: `wqzkSystem/getDictionaryItems?dictionaryType=SHEN_LUN_VIDEO`,
		method: 'get',
	})

}

//根据类型查询开考时间
export function queryExamtimeByType(data) {
	return crewordRequest({
		url: `wqbriefcase/twq-civil-servant-exam-time/queryExamtimeByType`,
		method: "post",
		data,
	});
}


export function getUserLocationByIp() {
	return crewordRequest({
		url: `common/getUserLocationByIp`,
		method: "get",
	});
}
export function queryEachExamnum(params) {
	return crewordRequest({
		url: `wqshenlun/twq-shen-lun-score-record/queryEachExamnum`,
		method: "get",
		params
	});
}
