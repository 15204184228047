import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "assets/scss/index.scss";
import store from "./store";
import "normalize.css";
import infiniteScroll from "vue-infinite-scroll";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import { getToken } from '@/utils/auth'
import { getuserInfo } from "@/api/service";
import { isLogin } from "./utils/auth";
import { isHasBridge, getQuerys } from "./utils/utils";
import { cancelAxios } from "./utils/cancelAxios";

import qs from "qs";

import "./views/pcMarkdown/interface";

Vue.use(infiniteScroll);

//获取当前Token的方法挂载到全局
Vue.prototype.$isLogin = isLogin;

Vue.prototype.$isHasBridge = isHasBridge;

Vue.config.productionTip = false;
Vue.prototype.$cancelAxios = cancelAxios;
window.qs = qs;

// 复制
Vue.prototype.$onCopy = (val, text = "复制成功") => {
  var input = document.createElement("input");
  input.value = val;
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input);
  Vue.prototype.$message.success(text);
};
// 更新用户信息 vuex
Vue.prototype.$setUserInfo = async () => {
  try {
    const res = await getuserInfo();
    if (res.code === 200) {
      store.commit("setUserInfo", res.data);

      return res.data;
    }
  } catch (e) { }
};

// pc 切换 h5
const pcToggleH5 = () => {
  // 获取token
  let token = getQuerys(window.location.href).token

  if (token) {
    localStorage.setItem("auth_token", token);
  }

  // 切换
  var ua = navigator.userAgent.match(/(phone|Android|Mobile)/i)
  let TokenObj = getToken()
  TokenObj = TokenObj ? JSON.parse(TokenObj).access_token : ''
  let Token = localStorage.getItem('auth_token');
  if (ua) {
    window.location.href = `https://mall.writemall.com/${(TokenObj || Token) ? `?token=${TokenObj || Token}` : ''}`
  }
}

pcToggleH5()

// 全局eventbus
let EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: function () {
    return { visible: false };
  },
}).$mount("#app");
