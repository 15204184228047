<template>
	<div class="buy-dialog" v-show="show">
		<div class="masklayer" @click="close"></div>
		<div class="body">
			<img class="bg" :src="wqUrl + 'buyVip_bg.png'" mode="widthFix">
			<img class="mark" :src="wqUrl + 'mark.png'" mode="">
			<div class="title">
				<span>{{info.title}}</span>
				<img class="text_bg" :src="wqUrl + 'text_bg.png'" mode="widthFix">
			</div>
			<div class="content">
				<!-- ai绘画弹框文字内容 -->
				<span v-if="aiDraw">VIP专用功能，开通VIP，即刻享用</span>
				<span v-else>
					{{info.content}}
				</span>
			</div>
			<div class="btn" @click="buy">
				查看会员详情
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "buyDialog",
	data() {
		return {
			wqUrl:
				"https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/wqzk/",
		};
	},
	computed: {
		show() {
			return this.$store.state.bugDialog
		},
		aiDraw() {
			return this.$store.state.drawWorkFlag
		},
		info() {
				return this.$store.state.buyDialogText
			},
	},
	methods: {
		buy() {
			const fullPath = this.$router.resolve({
				name: 'vipPage',
			}).href;
			//drawWorkId    控制显示ai绘画vip文字
			this.$store.commit('setDrawWorkId', false);
			this.$store.commit('setBugDialog', false);
			window.open(fullPath, "_blank")
		},
		close() {
			this.$store.commit('setDrawWorkId', false);
			this.$store.commit('setBugDialog', false);
		},
	}
}
</script>

<style scoped lang="scss">
.buy-dialog {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 2023;

	.masklayer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.3);
	}

	.body {
		position: absolute;
		top: 25%;
		left: 50%;
		transform: translateX(-50%);
		width: 326px;
		height: 262px;
		// padding: 60px 40px;
		box-sizing: border-box;

		.title {
			width: 112px;
			height: 24px;
			position: relative;
			margin: 30px auto;
			text-align: center;

			span {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #000000;
				position: relative;
				z-index: 2;
			}

			.text_bg {
				position: absolute;
				top: 15px;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.content {
			position: relative;
			margin: 50px auto 30px;
			width: 262px;
			height: 44px;
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 28px;
			text-align: center;
		}

		.btn {
			position: relative;
			width: 251px;
			height: 50px;
			background: linear-gradient(90deg, #FF3C3C, #FF943C);
			border-radius: 25px;
			text-align: center;
			line-height: 50px;
			color: #fff;
			margin: 0 auto;
			cursor: pointer;
		}

		.mark {
			position: absolute;
			top: -30px;
			right: -15px;
			width: 90px;
			height: 90px;
			z-index: 9;
		}

		.bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

}
</style>