import { OPTIONS } from "./constants";

// 保证 getHtml 输出的结构能够兼容到 wps 对应的标题目录
function genToHtmlFn(level) {
  function generateNodeStyle(node) {
    const { bold, italic, underline, code, through, sub, sup, text } = node;

    const $text = document.createElement("span");
    $text.innerText = text;
    let $wrapper = $text;

    const swap = (domType, $oldDom) => {
      const $newDom = document.createElement(domType);

      if ($oldDom) {
        $newDom.append($oldDom);
        $wrapper = $newDom;
      } else {
        const temp = $wrapper;
        $wrapper = $newDom;
        $wrapper.append(temp);
      }
    };

    if (bold) {
      swap("strong", $text);
    }
    if (code) {
      swap("code");
    }
    if (italic) {
      swap("em");
    }
    if (underline) {
      swap("u");
    }
    if (through) {
      swap("s");
    }
    if (sub) {
      swap("sub");
    }
    if (sup) {
      swap("sup");
    }

    return $wrapper.outerHTML;
  }

  function headerToHtml(el, childrenHtml, editor) {
    const renderOption = OPTIONS.find((option) => option.value === el.type);
    const wrapper = document.createElement(`h${level}`);
    wrapper.style.fontSize = renderOption.renderEl.fontSize;

    // 标题比较特殊，要做成和 wps 的标题加粗效果一样，可以对标题进行加粗和
    // 去除加粗效果。如果没有标记加粗效果，则默认走加粗，否则默认按标记了加粗的来进行加粗
    const html = el.children.map((node) => generateNodeStyle(node)).join("");

    return `<h${level} data-w-e-type="header${level}" data-slate-inline="false" data-inherit-node="true">${html}</h${level}>`;
  }
  return headerToHtml;
}

const getHeaderOrder = (order) => {
  return `header${order}`;
};

export const header1ToHtmlConf = {
  type: getHeaderOrder(1),
  elemToHtml: genToHtmlFn(1),
};

export const header2ToHtmlConf = {
  type: getHeaderOrder(2),
  elemToHtml: genToHtmlFn(2),
};

export const header3ToHtmlConf = {
  type: getHeaderOrder(3),
  elemToHtml: genToHtmlFn(3),
};

export const header4ToHtmlConf = {
  type: getHeaderOrder(4),
  elemToHtml: genToHtmlFn(4),
};

export const header5ToHtmlConf = {
  type: getHeaderOrder(5),
  elemToHtml: genToHtmlFn(5),
};
