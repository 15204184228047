import { TEXTAREA_EDIT_TYPE } from "@/views/pcMarkdown/config/wange/node";
import { UNDO_SVG } from "@/views/pcMarkdown/config/wange/constants";

function undo(editor) {
  editor.undo();

  const { undos } = editor.history;
  const operations = undos[undos.length - 1];

  if (!operations) {
    return;
  }

  // 处理 wangeditor 内部自带的插入节点和删除节点的空记录...
  if (undos.length === 1) {
    const isEmpty = operations.every((op) => {
      return (
        op?.node?.children?.length === 1 && op?.node?.children[0].text === ""
      );
    });

    if (isEmpty && editor?.isEmpty()) {
      undo(editor);
      return;
    }
  }

  // 存在重新高亮选区的操作，需要取前两个
  const op = operations[operations.length - 1];
  const prevOp = operations[operations.length - 2];

  // 跳过 e-textarea 阶段
  const hasCurrentTextarea =
    op?.node?.type === TEXTAREA_EDIT_TYPE ||
    op?.node?.children?.some((item) => {
      return item.type === TEXTAREA_EDIT_TYPE;
    });
  const hasPrevTextarea =
    prevOp?.node?.type === TEXTAREA_EDIT_TYPE ||
    prevOp?.node?.children?.some((item) => {
      return item.type === TEXTAREA_EDIT_TYPE;
    });

  if (hasCurrentTextarea || hasPrevTextarea) {
    undo(editor);
  }
}

export default class CustomUndoMenu {
  constructor() {
    this.title = "撤销";
    this.iconSvg = UNDO_SVG;
    this.tag = "button";
  }

  isActive() {
    return false;
  }

  getValue() {
    return "";
  }

  isDisabled(editor) {
    const { undos } = editor.history;
    const operations = undos[undos.length - 1];

    if (!operations) {
      return true 
    }

    // wangeditor 初始化阶段内部会自己做一个插入和删除的操作，会导致一条无用的记录
    // if (undos.length === 1) {
    //   const isEmpty = operations.every(op => {
    //     return op?.node?.children?.length === 1 && op?.node?.children[0].text === ''
    //   }) 

    //   if (isEmpty && editor?.isEmpty()) {
    //     return true
    //   }
    // }

    return false;
  }

  exec(editor) {
    if (this.isDisabled(editor)) {
      return;
    }

    undo(editor);
  }
}
