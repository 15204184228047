import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLogin } from '@/utils/auth';
import versionTood from '../utils/versionUpdate'
import store from '@/store'
Vue.use(VueRouter)

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => { })
  } else {
    originPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => { })
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/index',
    component: () => import('@/views/home/home.vue'),
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index/index.vue'),
        meta: {
          title: '首页',
          documentTitle: '挖潜智库',
        },
      },
      {
        path: 'userPage',
        name: 'userPage',
        component: () => import('@/views/userPage/userPage.vue'),
        meta: {
          title: '我的',
          documentTitle: '我的',
          isLogin: true,
        },
      },
      {
        path: 'morePage',
        name: 'morePage',
        component: () => import('@/views/morePage/morePage.vue'),
        meta: {
          title: '更多',
          documentTitle: '更多',
        },
      },
      {
        path: 'writingExisting',
        name: 'writingExisting',
        component: () => import('@/views/writingExisting/writingExisting.vue'),
        meta: {
          title: '一键成文',
          documentTitle: '一键成文',
        },
      },
      {
        path: 'professionalServices',
        name: 'professionalServices',
        component: () => import('@/views/professionalServices/professionalServices.vue'),
        meta: {
          title: '专业服务',
          documentTitle: '专业服务',
          tips: '专业的事放心交给专业的人去做',
        },
      },
      {
        path: 'editDocument',
        name: 'editDocument',
        component: () => import('@/views/editDocument/editDocument.vue'),
        meta: {
          title: '编辑',
          documentTitle: '编辑',
        },
      },
      {
        path: 'PCMarkdown',
        name: 'PCMarkdown',
        component: () => import('@/views/pcMarkdown/index.vue'),
        meta: {
          title: 'AI写手',
          documentTitle: 'AI写手',
        },
      },
      {
        path: 'essayPage',
        name: 'essayPage',
        component: () => import('@/views/essayPage/index.vue'),
      },
      {
        path: 'wordTool',
        name: 'wordTool',
        component: () => import('@/views/wordTool/index.vue'),
      },
    ]
  },
  {
    path: '/PCMarkdownEditor',
    name: 'PCMarkdownEditor',
    component: () => import('@/views/pcMarkdown/editor/index.vue'),
    meta: {
      title: 'AI写手',
      documentTitle: 'AI写手',
    },
  },
  {
    path: '/newWordS',
    name: 'NewWord',
    redirect: '/newWordS/addWord',
    component: () => import('@/views/newWordS/routerView'),
    children: [
      {
        path: 'addWord',
        name: 'MddWord',
        component: () => import('@/views/newWordS/addWord'),
        redirect: '/newWordS/addWord/checking',
        children: [
          {
            path: 'textImage',
            name: 'TextImage',
            component: () =>
              import('@/views/newWordS/addWordRight/textImage/textImageData'),
          },
          {
            path: 'library',
            name: 'Library',

            component: () =>
              import('@/views/newWordS/addWordRight/library/libraryDate'),
          },
          {
            path: 'translate',
            name: 'Translate',
            component: () =>
              import('@/views/newWordS/addWordRight/translate/translateData'),
          },
          {
            path: 'composing',
            name: 'Composing',
            component: () =>
              import('@/views/newWordS/addWordRight/composing/composingIndex'),
            meta: {
              headerTitle: 'AI智能校阅，为内容保驾护航'
            },

          },
          {
            path: 'aigc',
            name: 'Aigc',
            component: () =>
              import('@/views/newWordS/addWordRight/AiWrite/Aigc'),
            meta: {
              headerTitle: '当真实遭遇虚构，我们帮你分清界限'
            },
          },
          {
            path: 'aiwrite',
            name: 'AiWrite',
            component: () =>
              import('@/views/newWordS/addWordRight/AiWrite/AiWriteIndex'),
          },
          {
            path: 'checking',
            name: 'Checking',
            component: () =>
              import('@/views/newWordS/addWordRight/AiWrite/checking'),
            meta: {
              headerTitle: '智能识别文本样式，一键完成格式规范。'
            },
          },
          // {
          //   path: 'draftAIGC',
          //   name: 'DraftAIGC',
          //   component: () =>
          //     import(
          //       '@/views/newWordS/addWordRight/Oneclickwriting/writtenAi/DraftAIGC'
          //     ),
          // },
          {
            path: 'tools',
            name: 'Tools',
            component: () =>
              import('@/views/newWordS/addWordRight/tools/toolsIndex'),
          },
          {
            path: 'works-header',
            name: 'Disk',
            component: () => import('@/views/newWordS/addWordRight/disk/index'),
          },
          {
            path: 'original',
            name: 'Original',
            component: () =>
              import('@/views/newWordS/addWordRight/original/originalIndex'),
          },

          {
            path: 'collect',
            name: 'Collect',
            component: () =>
              import('@/views/newWordS/addWordRight/collect/collect'),
          },
          // 专业服务
          {
            path: 'specialized',
            name: 'Specialized',
            component: () =>
              import('@/views/newWordS/addWordRight/specialized/specialized'),
          },
          // 专业服务详情
          {
            path: 'ServiceDetails',
            name: 'ServiceDetails',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/specialized/ServiceDetails'
              ),
          },

          {
            path: 'writeAndSearch',
            name: 'WriteAndSearch',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/writeAndSearch/writeAndSearch'
              ),
          },
          {
            path: 'helpLibrary',
            name: 'HelpLibrary',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/helpLibrary/helpLibraryIndex'
              ),
          },
          // AI成文
          {
            path: 'Oneclickwriting',
            name: 'Oneclickwriting',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/Oneclickwriting'
              ),
          },
          // AI成文详情
          // {
          //   path: 'OneclickwritingDilets',
          //   name: 'OneclickwritingDilets',
          //   component: () =>
          //     import(
          //       '@/views/newWordS/addWordRight/Oneclickwriting/OneclickwritingDilets'
          //     ),
          // },
          // 咨询服务详情
          {
            path: 'formation',
            name: 'formation',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/formation.vue'
              ),
          },
          {
            path: 'Social',
            name: 'Social',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/Social.vue'
              ),
          },
          {
            path: 'RaftAIGC',
            name: 'RaftAIGC',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/DraftAIGC.vue'
              ),
          },
          // 评论列表
          {
            path: 'Comment',
            name: 'Comment',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/Oneclickwriting/Comment.vue'
              ),
          },

          // 评论列表
          {
            path: 'specializedComment',
            name: 'specializedComment',
            component: () =>
              import(
                '@/views/newWordS/addWordRight/specialized/components/Comment.vue'
              ),
          },
          {
            path: 'paintingCommunity',
            name: 'paintingCommunity',
            component: () =>
              import('@/views/newWordS/addWordRight/paintingCommunity/index'),
          },
        ],
      },
    ],
  },
  {
    path: '/draWing',
    name: 'draWing',
    component: () => import('@/views/draWing/index.vue'),
    meta: {
      title: 'AI绘画',
    },
  },
  {
    path: '/discover',
    name: 'discover',
    component: () =>
      import('@/views/draWing/components/drawingBoard/components/discover'),
    meta: {
      title: '发现',
    },
  },
  {
    path: '/AIPainting',
    name: 'AIPainting',
    component: () => import('@/views/aiPainting/index.vue'),
    meta: {
      documentTitle: 'AI绘画',
    },
  },
  {
    path: '/legalAffairs',
    name: 'LegalAffairs',
    component: () => import('@/views/aiLegalAffairs/aiLegalAffairs.vue'),
  },
  {
    path: '/vipPage', // vip 会员页面
    name: 'vipPage',
    component: () => import('@/views/vipPage/vipPage.vue'),
  },
  {
    path: '/essay',
    name: 'essay',
    component: () => import('@/views/essay/essay.vue'),
  },
  {
    path: '/joinUsPage', // 入驻平台
    name: 'joinUsPage',
    component: () => import('@/views/joinUsPage/joinUsPage.vue'),
  },
  {
    path: '/collectpage',
    name: 'collectPage',
    component: () => import('@/views/collectPage/index'),
  },
  {
    path: '/taskList',
    name: 'TaskList',
    component: () => import('@/views/collectPage/collectTaskList'),
  },
  {
    path: '/buyIntegration',
    name: 'buyIntegration',
    component: () => import('@/views/buyIntegration/index'),
  },
  {
    path: '/createcollect',
    name: 'createCollect',
    component: () => import('@/views/createCollect/index'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const icons = [{
  name: 'pcmarkdown',
  icon: store.state.wqUrl + "write-client-word-markdown.png"
},
{
  name: 'checking',
  icon: store.state.wqUrl + "write-client-word.png"
},
{
  name: 'composing',
  icon: store.state.wqUrl + "write-client-word.png"
},
{
  name: 'aigc',
  icon: store.state.wqUrl + "write-client-word.png"
},
{
  name: 'library',
  icon: store.state.wqUrl + "write-client-word.png"
},
{
  name: 'disk',
  icon: store.state.wqUrl + "write-client-word.png"
},
{
  name: 'aipainting',
  icon: store.state.wqUrl + "ai_draw.png"
}
]

router.beforeEach((to, form, next) => {
  const linkElements = document.getElementsByTagName('link')
  for (let i = 0; i < linkElements.length; i++) {
    const linkElement = linkElements[i]
    if (linkElement.getAttribute('rel') === 'icon') {
      if (to.name) {
        const lowerCaseWord = to.name.toLowerCase()
        const target = icons.find(icon => lowerCaseWord.match(icon.name))
        if (target) {
          linkElement.setAttribute('href', target.icon)
        }
      }

    }
  }

  versionTood.isNewVersion()
  // console.log(to.meta.documentTitle);
  if (to.meta.documentTitle) {
    // window.document.title = to.meta.documentTitle
  }
  if (to.meta.headerTitle) {
    store.commit('setHeaderTitle', to.meta.headerTitle);
  }
  // headerTitle
  if (to.meta.isLogin) {
    const pass = isLogin();
    console.log(pass);
    if (!pass) {
      next();
    }
  } else {
    next();
  }
})

export default router
