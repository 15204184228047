import { h } from "snabbdom";
import shapeIcon from "@/assets/images/ai/shape.png";

export const TEXTAREA_EDIT_KEY = "markdown-editor-textarea-node";
export const TEXTAREA_EDIT_LOADING = "markdown-editor-textarea-loading";
export const TEXTAREA_EDIT_TYPE = "e-textarea";
export const TEXTAREA_EDIT_TIP = "markdown-editor-textarea-tip";

export const renderElements = [
  {
    type: TEXTAREA_EDIT_TYPE,
    renderElem() {
      const $loading = h(
        "span",
        {
          props: {
            className: `${TEXTAREA_EDIT_LOADING}-wrapper`,
          },
        },
        [
          "正在思考中",
          h(
            "span",
            {
              props: {
                className: TEXTAREA_EDIT_LOADING,
              },
            },
            ["..."]
          ),
        ]
      );

      const $tip = h(
        "span",
        {
          id: TEXTAREA_EDIT_TIP,
          style: {
            position: "absolute",
            bottom: "0px",
            right: "-20px",
            fontSize: "12px",
            height: "20px",
            transform: "scale(.8)",
            display: "inline-flex",
            alignItems: "center",
            color: "#666",
          },
        },
        [
          h("img", {
            props: {
              src: shapeIcon,
            },
            style: {
              minWidth: "unset",
              minHeight: "unset",
              width: "12px",
              margin: "0 6px 0 0",
            },
          }),
          "AI生成的内容仅供参考，请注意甄别信息准确性。",
        ]
      );

      const $content = h("div", {
        props: {
          className: `${TEXTAREA_EDIT_KEY}-content`,
        },
      });

      // 生成后可编辑，需要改成textarea, 但是会丢失样式
      // 如果样式要保留，使用p标签，但是会丢失编辑功能
      // return h("textarea", {
      return h(
        "div",
        {
          props: {
            className: TEXTAREA_EDIT_KEY,
            contentEditable: false,
          },
          style: {
            position: "relative",
            border: "solid 1px #666",
            border: "2px solid #3A68F4",
            borderRadius: "5px",
            padding: "6px 10px 22px 10px",
            minHeight: "52px",
            width: "100%",
            lineHeight: "20px",
          },
          on: {
            click(event) {
              event.stopPropagation();
            },
          },
        },
        [$content, $loading, $tip]
      );
    },
  },
];
