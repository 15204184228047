import { MarkdownEditor } from "./editor/utils/node";
import router from '@/router'

const ERROR = {
  INIT: {
    code: -1,
    message: "未初始化",
  },
};

window.$writeEditor = {
  _initEvent: null,

  init(callback) {
    this._initEvent = this.created.bind(this, callback);
    MarkdownEditor.on("created", this._initEvent);
  },

  created(callback, editor) {
    callback?.(editor);
  },

  destroyed() {
    MarkdownEditor.off("created", this._initEvent);
    MarkdownEditor.destroyed();
  },

  // 插入文本
  insertText(text) {
    if (!MarkdownEditor.e) {
      throw Error(ERROR.INIT.message, { cause: ERROR.INIT });
    }

    const isDisabled = MarkdownEditor.e.isDisabled();

    if (isDisabled) {
      return;
    }

    MarkdownEditor.e.insertText(text);
  },

  // 设置编辑区的html内容（重置）
  setHtml(html) {
    if (!MarkdownEditor.e) {
      throw Error(ERROR.INIT.message, { cause: ERROR.INIT });
    }

    const isDisabled = MarkdownEditor.e.isDisabled();

    if (isDisabled) {
      return;
    }

    MarkdownEditor.e.setHtml(html);
  },

  // 追加 html 片段
  insertHtml(html) {
    if (!MarkdownEditor.e) {
      throw Error(ERROR.INIT.message, { cause: ERROR.INIT });
    }

    const isDisabled = MarkdownEditor.e.isDisabled();

    if (isDisabled) {
      return;
    }

    MarkdownEditor.e.dangerouslyInsertHtml(html);
    // MarkdownEditor.transformEditNodeContext(html);
    // MarkdownEditor.insertNodes()
  },

  // 获取选中文本内容
  getSelectionText() {
    if (!MarkdownEditor.e) {
      throw Error(ERROR.INIT.message, { cause: ERROR.INIT });
    }

    return MarkdownEditor.e.getSelectionText();
  },

  // 立即编辑
  gotoEditor(html) {
    const fullPath = router.resolve({
      name: "PCMarkdownEditor",
    }).href;

    localStorage.setItem("generateHtml", html)
    window.open(fullPath, "_blank");
  }
};

// example
// window.$writeEditor.init((editor) => {
//   console.log("嘎嘎");
//   window.$writeEditor.insertText('嘎嘎')
// });
