import axios from "axios";
import { getToken } from "@/utils/auth";

const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${process.env.VUE_APP_BASE_API}oauthapi`
      : "/oauthapi",
  timeout: 10000,
});

myAxios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = "Basic Y2xpZW50LWFwOjFxYXp4c3cy";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

myAxios.interceptors.response.use(
  (config) => {
    return config.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default myAxios;
