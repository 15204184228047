import { EDITOR_STATUS } from "@/views/pcMarkdown/config/wange";

const markdown = {
  namespaced: true,
  state: {
    editorStatus: EDITOR_STATUS.LOCKING,
    markdownTitle: "",

    toolbarVisible: false,
    commandDialogVisible: false,

    keyboard: {
      visible: false,
      bottom: 0
    },
    vipDialogVisible: false,
    vipDialogContext: {
      title: '开通会员',
      content: '当前次数已经用完,开通VIP可获取更多次数',
      vipText: "30次/日",
      isRecharge: true,
      isVip: false,
      isPay: false,
      payText: "积分不足",
      toPayCallback: () => { },
    },
    sceneTypeList: [],
  },
  mutations: {
    // 设置文档标题
    SET_MARKDOWN_TITLE(state, value) {
      state.markdownTitle = value;
    },
    // 工具栏状态
    SET_TOOLBAR_VISIBLE(state, value) {
      state.toolbarVisible = value;
    },
    SET_VIP_DIALOG_VISIBLE(state, value) {
      state.vipDialogVisible = value
    },
    SET_VIP_DIALOG_CONTEXT(state, value) {
      state.vipDialogContext = value
    },
    // 更新编辑器状态
    SET_EDITOR_STATUS(state, value) {
      state.editorStatus = value;
    },
    // 设置键盘状态
    SET_KEYBOARD(state, value) {
      state.keyboard = value
    },
    // 设置指令弹窗
    SET_COMMAND_DIALOG_VISIBLE(state, value) {
      state.commandDialogVisible = value
    },
    // 写作场景列表
    SET_SCENE_TYPE_LIST(state, value) {
      state.sceneTypeList = (value || [])
        .map((item) => {
          return {
            name: item.name,
            sceneTypes: item.sceneTypes || [],
          };
        })
        .filter((operation) => {
          return operation.sceneTypes.length > 0;
        });
    },
    // 更新场景热点
    UPDATE_SCENE_TYPE_HOT(state, value) {
      const sceneTypes = state.sceneTypeList.find(item => item.name === value.name)?.sceneTypes || []
      const target = sceneTypes.find(item => item.id === value.id)

      if (target) {
        target.hot += 1;
      }
    },
  },
  actions: {
    keyboardChange({ commit }, message) {
      commit('SET_KEYBOARD', message);
    }
  },
  getters: {},
};

export default markdown;
