import { Boot } from "@wangeditor/editor";
import {
  renderElements,
  TEXTAREA_EDIT_KEY,
  TEXTAREA_EDIT_TYPE,
  TEXTAREA_EDIT_LOADING,
  TEXTAREA_EDIT_TIP,
} from "./node";
import './modules/register-buildin'
import { toolbarConfig } from "./toolbar";
import { editorPlugin } from "./plugin";
import Cookies from 'js-cookie'

export { COLORS } from './constants'

Boot.registerModule({
  editorPlugin,
  renderElems: renderElements,
});

const EDITOR_STATUS = {
  LOCKING: 0, // 锁定中
  EDITING: 1, // 编辑中
  REPEATING: 2, // 替换中
  GENERATING: 3, // 生成中
};

const EDITOR_CONTAINER_KEY = "markdown-editor-container";
const EDITOR_TOOLBAR_KEY = "markdown-editor-toolbar";
const EDITOR_HOVER_BAR_KEY = "markdown-hoverbar";
const EDITOR_TITLE_KEY = "markdown-title"
const EDITOR_VISUAL_INPUT_KEY = "markdown-visual-input"; // 做编辑器聚焦丢失后，保存键盘弹起的聚焦操作的元素

export {
  EDITOR_STATUS,
  EDITOR_CONTAINER_KEY,
  EDITOR_HOVER_BAR_KEY,
  EDITOR_VISUAL_INPUT_KEY,
  EDITOR_TOOLBAR_KEY,
  EDITOR_TITLE_KEY,
  TEXTAREA_EDIT_KEY,
  TEXTAREA_EDIT_LOADING,
  TEXTAREA_EDIT_TYPE,
  TEXTAREA_EDIT_TIP,
};

export {
  toolbarConfig
}

export const config = {
  autoFocus: true,
  readOnly: false,
  scroll: false,
  placeholder: "文档内容（点击输入）",
  mode: "simple",
  // 在 textarea < 400 的情况下会定位错误。具体请看 https://github.com/wangeditor-team/wangEditor/issues/5488
  // 这里采用 customDom + selection.focus + popover 处理
  hoverbarKeys: {
    text: {
      // menuKeys: menus.map((menu) => menu.key),
    },
  },
  MENU_CONF: {
    uploadImage: {
      fieldName: "file",
      server: "/api/common/upload",
      maxFileSize: 5 * 1024 * 1024,
      allowedFileTypes: ["image/*"],
      timeout: 10 * 1000,
      customInsert(res, insertFn) {
        insertFn("https://fafs.antiplagiarize.com/" + res.data, "", "");
      },
      headers: {
        Authorization: "bearer " + JSON.parse(Cookies.get()['auth_token'] || "{}")?.access_token,
      },
    },
    uploadVideo: {
      fieldName: "file",
      server: "/api/common/upload",
      maxFileSize: 5 * 1024 * 1024,
      allowedFileTypes: ["video/*"],
      timeout: 10 * 1000,
      customInsert(res, insertFn) {
        insertFn("https://fafs.antiplagiarize.com/" + res.data, "", "");
      },
      headers: {
        Authorization: "bearer " + JSON.parse(Cookies.get()['auth_token'] || "{}")?.access_token,
      },
    },
  },
};
